import { ReferralGiftPayload } from "../../types/referral-gifts-types";

export const extractReferralGiftData = (
  data: ReferralGiftPayload,
  type: "sender" | "recipient"
) => {
  if (!data) return {};

  if (type === "sender") {
    return {
      sender_referral_gift_id: data.sender_referral_gift_id || "",
      sender_referral_gift_type: data.sender_referral_gift_type || "item",
      sender_referral_gift_price: data.sender_referral_gift_price || 0,
      sender_referral_gift_name: data.sender_referral_gift_name || "",
      sender_referral_gift_image: data.sender_referral_gift_image || "",
    };
  } else {
    return {
      recipient_referral_gift_id: data.recipient_referral_gift_id || "",
      recipient_referral_gift_type: data.recipient_referral_gift_type || "item",
      recipient_referral_gift_price: data.recipient_referral_gift_price || 0,
      recipient_referral_gift_name: data.recipient_referral_gift_name || "",
      recipient_referral_gift_image: data.recipient_referral_gift_image || "",
    };
  }
};

import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../../../redux-store/selectors";
import { createWorkingHoursScheduleAction } from "../../../../constants/actions";
import {
  createWorkingHoursSchedule,
  createWorkingHoursScheduleSuccess,
  createWorkingHoursScheduleFailure,
  selectWorkingHoursSchedule,
  getStoreWorkingHours,
  clearWorkingHoursSchedule,
} from "../../../actions/Manager/Settings/workingHoursActions";
import { notify } from "react-notify-toast";
import { createWorkingHoursScheduleApi } from "../../../../axios/Manager";
import { union } from "ts-action";
import { WorkingHoursSchedule } from "../../../../../../types";
import { AxiosResponse } from "axios";
import { translate } from "../../../../../../helpers/translate";
import Strings from "../../../../i18n/strings/brandOpeningHours";

const t = translate("brandOpeningHours");
const actionTypes = union({ createWorkingHoursSchedule });
export function* createWorkingHoursScheduleSaga({
  payload,
}: typeof actionTypes) {
  try {
    const token = yield select(selectToken);
    const response: AxiosResponse<WorkingHoursSchedule> = yield call(
      createWorkingHoursScheduleApi,
      token,
      payload
    );

    yield put(createWorkingHoursScheduleSuccess(response.data));
    if (response.data.id) {
      yield put(clearWorkingHoursSchedule());
      yield put(selectWorkingHoursSchedule(response.data.id));
      yield put(getStoreWorkingHours(response.data.id));
    }
    notify.show(t(Strings.workingHoursScheduleCreatedSuccessfully), "success");
  } catch (error) {
    yield put(createWorkingHoursScheduleFailure(error as Error));
    notify.show(t(Strings.somethingWentWrong), "error");
  }
}

export function* watchCreateWorkingHoursSchedule() {
  yield takeLatest(
    createWorkingHoursScheduleAction.requested,
    createWorkingHoursScheduleSaga
  );
}

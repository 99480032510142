import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../selectors";
import {
    getReferralMenu,
    getReferralMenuSuccess,
    getReferralMenuFailure,
} from "../../actions";
import { getReferralMenuAction } from "../../../constants";
import { getReferralMenuItems } from "../../../Components/Pickup/axios/getReferralMenuItems";
import { notify } from "react-notify-toast";

function* getReferralMenuSaga({ payload }: ReturnType<typeof getReferralMenu>) {
    try {
        const token = yield select(selectToken);
        const { store_id } = payload || {};
        const response = yield call(getReferralMenuItems, token, store_id);

        yield put(getReferralMenuSuccess({ menu: response.data }));
    } catch (error) {
        yield put(getReferralMenuFailure(error as Error));
        notify.show("Failed to load referral menu items", "error");
    }
}

export function* watchGetReferralMenuSaga() {
    yield takeLatest(getReferralMenuAction.requested, getReferralMenuSaga);
}
import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../../../redux-store/selectors";
import { getWorkingHoursSchedulesAction } from "../../../../constants/actions";
import {
    getStoreWorkingHours,
    getWorkingHoursSchedulesFailure,
    getWorkingHoursSchedulesSuccess,
    selectWorkingHoursSchedule,
} from "../../../actions/Manager";
import { getWorkingHoursSchedulesApi } from "../../../../axios/Manager";

function* getWorkingHoursSchedulesSaga() {
    try {
        const token = yield select(selectToken);
        const res = yield call(getWorkingHoursSchedulesApi, token);
        const activeWorkingHours = res.data.find((item: any) => item.is_active);
        yield put(getWorkingHoursSchedulesSuccess(res.data));
        if (activeWorkingHours?.id) {
            yield put(selectWorkingHoursSchedule(activeWorkingHours.id));
            yield put(getStoreWorkingHours(activeWorkingHours.id));
        }
    } catch (error) {
        yield put(getWorkingHoursSchedulesFailure(error as Error));
    }
}

export function* watchGetWorkingHoursSchedulesSaga() {
    yield takeLatest(
        getWorkingHoursSchedulesAction.requested,
        getWorkingHoursSchedulesSaga
    );
}

import { on, reducer } from "ts-action";
import {
  LoadingStatus,
  withLoadingReducer,
} from "../../Components/Pickup/redux-store/reducers/withLoadingState";
import { getReferralGiftsAction } from "../../constants";
import {
  ReferralGiftPayload,
  ReferralProgramGift,
} from "../../types/referral-gifts-types";
import {
  addEmptyReferralGift,
  deleteReferralGiftSuccess,
  getReferralGiftsSuccess,
  modifyReferralGift,
  modifyReferralGiftFailure,
  modifyReferralGiftSuccess,
  removeEmptyReferralGift,
  resetReferralGiftStatus,
  selectReferralGift,
} from "../actions";
import { times } from "lodash";
import { prepareReferralGiftCollection } from "../../helpers/referral-gifts/prepareReferralGiftCollection";
import { extractReferralGiftData } from "../../helpers/referral-gifts/extractReferralGiftData";
import { createEmptyReferralGift } from "../../helpers/referral-gifts/createEmptyReferralGift";

export type PendingChanges = {
  data: ReferralGiftPayload;
  rowIndex: number;
  columnType: "sender" | "recipient";
};

type ReferralGiftsState = {
  referralGifts: ReferralProgramGift[];
  status: LoadingStatus | undefined;
  selectedRow?: {
    index: number;
    columnType: "sender" | "recipient";
    referral_id?: string;
    id?: string;
    sender_referral_gift_id?: string;
    recipient_referral_gift_id?: string;
  };
  pendingChanges: Record<number, PendingChanges>;
};

const initialState: ReferralGiftsState = {
  referralGifts: times(5, (i) => createEmptyReferralGift(i)),
  status: undefined,
  selectedRow: undefined,
  pendingChanges: {},
};

export default withLoadingReducer<ReferralGiftsState>(
  reducer<ReferralGiftsState>(
    [
      on(getReferralGiftsSuccess, (state, { payload }) => ({
        ...state,
        referralGifts: prepareReferralGiftCollection(
          payload.map((item, index) =>
            item
              ? {
                ...item,
                is_complete:
                  !!item.referral_id &&
                  !!item.sender_referral_gift_id &&
                  !!item.recipient_referral_gift_id,
              }
              : createEmptyReferralGift(index)
          )
        ),
      })),

      on(modifyReferralGift, (state, { payload }) => {
        const targetRowIndex = state.referralGifts.findIndex(
          (item) => item.index === payload.rowIndex
        );

        if (targetRowIndex === -1) return state;

        const currentGiftRecord = state.referralGifts[targetRowIndex];
        const isSenderUpdate = payload.columnType === "sender";
        const giftData = extractReferralGiftData(
          payload.data,
          payload.columnType
        );
        const hasSenderDetails = isSenderUpdate
          ? Boolean(payload.data.sender_referral_gift_id)
          : Boolean(currentGiftRecord.sender_referral_gift_id);

        const hasRecipientDetails = !isSenderUpdate
          ? Boolean(payload.data.recipient_referral_gift_id)
          : Boolean(currentGiftRecord.recipient_referral_gift_id);

        const updatedGiftRecord = {
          ...currentGiftRecord,
          ...giftData,
          referral_id:
            payload.data.referral_id || currentGiftRecord.referral_id,
          is_complete:
            Boolean(payload.data.referral_id) ||
            (hasRecipientDetails && hasSenderDetails),
        };

        const updatedGiftCollection = [
          ...state.referralGifts.slice(0, targetRowIndex),
          updatedGiftRecord,
          ...state.referralGifts.slice(targetRowIndex + 1),
        ];

        const existingChange = state.pendingChanges[payload.rowIndex];

        const mergedData = {
          ...existingChange?.data,
          ...payload.data,
        };

        const newChange = {
          data: mergedData,
          rowIndex: payload.rowIndex,
          columnType: payload.columnType,
        };

        return {
          ...state,
          referralGifts: updatedGiftCollection,
          pendingChanges: {
            ...state.pendingChanges,
            [payload.rowIndex]: newChange,
          },
        };
      }),

      on(modifyReferralGiftSuccess, (state, { payload }) => {
        const existingIncompleteGifts = state.referralGifts.filter(
          gift =>
            !gift.is_complete &&
            (gift.sender_referral_gift_id || gift.recipient_referral_gift_id)
        );

        const completeGifts = payload.filter(
          gift => gift && gift.referral_id &&
            gift.sender_referral_gift_id &&
            gift.recipient_referral_gift_id
        );

        const combinedGifts = [
          ...completeGifts,
          ...existingIncompleteGifts
        ].map((gift, index) => ({
          ...gift,
          index,
          is_complete: Boolean(
            gift.referral_id &&
            gift.sender_referral_gift_id &&
            gift.recipient_referral_gift_id
          )
        }));

        const referralGifts = prepareReferralGiftCollection(combinedGifts);

        return {
          ...state,
          referralGifts,
          selectedRow: undefined,
          pendingChanges: {},
        };
      }),

      on(modifyReferralGiftFailure, (state) => ({
        ...state,
        status: LoadingStatus.failed,
      })),

      on(deleteReferralGiftSuccess, (state, { payload }) => {
        const updatedGifts = state.referralGifts.filter(
          (gift) => gift.referral_id !== payload.referral_id
        );

        const referralGifts = prepareReferralGiftCollection(updatedGifts);
        const newPendingChanges = { ...state.pendingChanges };

        const deletedIndex = state.referralGifts.findIndex(
          (gift) => gift.referral_id === payload.referral_id
        );

        if (deletedIndex !== -1) {
          delete newPendingChanges[deletedIndex];
        }

        return {
          ...state,
          referralGifts,
          pendingChanges: newPendingChanges,
        };
      }),

      on(selectReferralGift, (state, { payload }) => ({
        ...state,
        selectedRow: payload,
      })),

      on(addEmptyReferralGift, (state) => ({
        ...state,
        referralGifts: [
          ...state.referralGifts,
          createEmptyReferralGift(state.referralGifts.length),
        ],
      })),

      on(removeEmptyReferralGift, (state, { payload }) => {
        let updatedGifts = [...state.referralGifts];
        const newPendingChanges = { ...state.pendingChanges };

        if (payload.index <= 4) {
          updatedGifts[payload.index] = createEmptyReferralGift(payload.index);
        } else {
          updatedGifts = updatedGifts.filter((gift) => gift.index !== payload.index);
          updatedGifts = updatedGifts.map((gift, idx) => ({
            ...gift,
            index: idx,
          }));
        }

        delete newPendingChanges[payload.index];

        return {
          ...state,
          referralGifts: updatedGifts,
          pendingChanges: newPendingChanges,
          selectedRow: undefined,
        };
      }),

      on(resetReferralGiftStatus, (state, { payload }) => {
        const updatedGifts = [...state.referralGifts];

        if (updatedGifts[payload]) {
          updatedGifts[payload] = {
            ...updatedGifts[payload],
            recently_added: false,
          };
        }

        return {
          ...state,
          referralGifts: updatedGifts,
        };
      }),
    ],
    initialState
  ),
  getReferralGiftsAction
);

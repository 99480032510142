export interface Countries {
  code: string;
  ISO_code: string;
  icon: string;
  ar_name: string;
  en_name: string;
}
export const Countries: Countries[] = [
  {
    code: "+2",
    ISO_code: "eg",
    icon: require('../assets/egyptFlag.png').default,
    ar_name: "مصر",
    en_name: "Egypt",
  },
  {
    code: "+966",
    ISO_code: "sa",
    icon: require('../assets/saudiFlag.png').default,
    ar_name: "المملكة العربية السعودية",
    en_name: "Saudi Arabia",
  },
  {
    code: "+973",
    ISO_code: "bh",
    icon: require('../assets/bahrainFlag.png').default,
    ar_name: "البحرين",
    en_name: "Bahrain",
  }
];

import { call, put, select, takeLatest } from "redux-saga/effects";
import { deleteReferralGiftsAction } from "../../../constants";
import {
  deleteReferralGiftSuccess,
  deleteReferralGiftFailure,
} from "../../actions/referralGiftsActions";
import { selectToken } from "../../selectors";
import { notify } from "react-notify-toast";
import { translate } from "../../../helpers/translate";
import { deleteReferralGift } from "../../actions/referralGiftsActions";
import { deleteReferralGiftApi } from "../../../Components/Pickup/axios/referral-gifts";
import { IRootReducerState } from "../../rootReducer";

type DeleteReferralGiftAction = ReturnType<typeof deleteReferralGift>;

function* deleteReferralGiftSaga(action: DeleteReferralGiftAction) {
  const t = translate("referralGifts");
  const referralGift = yield select((state: IRootReducerState) =>
    state.referralGiftsReducer.referralGifts.find(
      (gift) => gift.referral_id === action.payload.referral_id
    )
  );
  try {
    const token = yield select(selectToken);
    yield call(deleteReferralGiftApi, token, action.payload);

    yield put(
      deleteReferralGiftSuccess({
        referral_id: action.payload.referral_id,
        sender_referral_gift_id: referralGift.sender_referral_gift_id,
        recipient_referral_gift_id: referralGift.recipient_referral_gift_id,
      })
    );

    notify.show(t("Referral gift deleted successfully"), "success");
  } catch (error) {
    yield put(deleteReferralGiftFailure(error as Error));
    notify.show(t("Failed to delete referral gift"), "error");
  }
}

export function* watchDeleteReferralGiftSaga() {
  yield takeLatest(deleteReferralGiftsAction.requested, deleteReferralGiftSaga);
}

import advancedSettings from "./advancedSettings";

export default {
  fraudCenter: "fraudCenter",
  leaderBoard: "leaderBoard",
  reviews: "reviews",
  reviewsResolution: "reviewsResolution",
  reviewsIssues: "reviewsIssues",
  branchesAndCashiers: "branchesAndCashiers",
  brandSettings: "brandSettings",
  dashboardUsers: "dashboardUsers",
  brandListing: "brandListing",
  brandInfo: "brandInfo",
  brandVisuals: "brandVisuals",
  menuItems: "menuItems",
  myProfile: "myProfile",
  settings: "settings",
  giftList: "giftList",
  referralGifts: "referralGifts",
  socialLinks: "socialLinks",
  giftingPoints: "giftingPoints",
  convertingRatio: "convertingRatio",
  orderHistory: "orderHistory",
  qrOrdering: "qrOrdering",
  orderingPortal: "orderingPortal",
  support: "support",
  help: "help",
  logout: "logout",
  main: "main",
  myAccount: "myAccount",
  branchesAndMain: "branchesAndMain",
  advancedSettings: "advancedSettings",
  pickup: "pickup",
  pickupBranches: "pickupBranches",
  pickupMenu: "pickupMenu",
  menu: "menu",
  home: "home",
  feedback: "feedback",
  adsManager: "adsManager",
  orders: "orders",
  insights: "insights",
  transactions: "transactions",
  redemptions: "redemptions",
  customers: "customers",
  branches: "branches",
  profile_settings: "profile_settings",
  posSettings: "posSettings",
  blockedUsers: advancedSettings.blockedUsers,
};

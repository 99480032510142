import axios from "./axios";
import * as Requests from "./requests";

export const getReferralMenuItems = (
    token: string,
    store_id: string,
) => {
    return axios.get(Requests.REFERRAL_MENU_ITEMS_API(store_id), {
        headers: {
            token,
        },
        baseURL: process.env.REACT_APP_PICKUP_ENDPOINT,
    });
};
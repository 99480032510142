import React from "react";
import Switch, { ReactSwitchProps } from "react-switch";
import styles from "./styles.module.sass";
import { useRtlClass } from "../../../lib";

export const SettingsSwitch = ({
  onHandleColor,
  handleDiameter,
  uncheckedIcon,
  boxShadow,
  activeBoxShadow,
  checkedIcon,
  onColor,
  height,
  width,
  className,
  ...props
}: ReactSwitchProps) => {
  return (
    <Switch
      onHandleColor={onHandleColor ?? "#fff"}
      handleDiameter={handleDiameter ?? 18}
      uncheckedIcon={uncheckedIcon ?? false}
      checkedIcon={checkedIcon ?? false}
      boxShadow={boxShadow ?? "0 1px 4px 0 rgba(0, 0, 0, 0.1)"}
      activeBoxShadow={activeBoxShadow ?? "0 1px 4px 0 rgba(0, 0, 0, 0.1)"}
      height={height ?? 20}
      width={width ?? 44}
      onColor={onColor ?? "#ffa351"}
      {...props}
      className={`${styles.settingSwitch} ${
        className || ""
      } ${useRtlClass(styles)}`}
    />
  );
};

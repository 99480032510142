import { BranchesWorkingHoursState } from "../Components/Pickup/redux-store/reducers/Manager/Settings/getBranchWorkingHoursReducer";
import { UpdateBranchWorkingHoursField } from "../types";

export const updateBranchIntervals = (
    state: BranchesWorkingHoursState,
    payload: UpdateBranchWorkingHoursField
) => {
    const targetBranch = state.branchesWorkingHours[payload.branch_id];
    if (!targetBranch) {
        return state;
    }
    const targetSchedule = targetBranch[payload.type];

    if (!targetSchedule) {
        return state;
    }

    const intervalIndex = targetSchedule.findIndex(
        (interval) => interval.start_day === payload.day
    );

    if (intervalIndex === -1) {
        return state;
    }

    const currentInterval = targetSchedule[intervalIndex];
    const hasStartTimeChange =
        payload.start_time && payload.start_time !== currentInterval.start_time;
    const hasEndTimeChange =
        payload.end_time && payload.end_time !== currentInterval.end_time;

    if (!hasStartTimeChange && !hasEndTimeChange) {
        return state;
    }

    const updatedInterval = {
        ...currentInterval,
        end_day: payload.day,
        start_day: payload.day,
        ...(hasStartTimeChange && { start_time: payload.start_time }),
        ...(hasEndTimeChange && { end_time: payload.end_time }),
    };

    const updatedSchedule = [...targetSchedule];
    updatedSchedule[intervalIndex] = updatedInterval;

    return {
        ...state,
        branchesWorkingHours: {
            ...state.branchesWorkingHours,
            [payload.branch_id]: {
                ...targetBranch,
                [payload.type]: updatedSchedule,
            },
        },
    };
};

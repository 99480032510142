import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../redux-store/selectors";
import { getStoreSettingsAction } from "../../constants/actions";
import {
  getStoreSettingsSuccess,
  getStoreSettingsFailure,
} from "../actions/StoreSettings";
import { getStorePickup } from "../../axios/getStorePickupSettings";
import { getStoreWorkingHours, selectWorkingHoursSchedule } from "../actions";

function* getStoreSettingsSaga() {
  try {
    const token = yield select(selectToken);
    const res = yield call(getStorePickup, token);

    yield put(
      getStoreSettingsSuccess(
        res.data || { general: {}, working_hours: { intervals: [] } }
      )
    );
  } catch (error) {
    yield put(getStoreSettingsFailure(error as Error));
  }
}

export function* watchGetStoreSettingsSaga() {
  yield takeLatest(getStoreSettingsAction.requested, getStoreSettingsSaga);
}

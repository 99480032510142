import React, { useEffect } from "react";
import styles from "./styles.module.sass";
import { useDispatch } from "react-redux";
import { useSelect } from "../../../../hooks/useSelect";
import { LoadingPageInfo } from "../../../../Components/Leaderboard/LoadingPageInfo";
import { LoadingStatus } from "../../../../Components/Pickup/redux-store/reducers/withLoadingState";
import { Plus } from "react-feather";
import {
  getVouchers,
  modifyReferralGift,
} from "../../../../redux-store/actions";
import { IPromocode } from "../../../../types";
import CouponIcon from "../../../../assets/coupon.png";
import { useGetCurrency } from "../../../../Utils/getLocalizedCurrency";
import { extractReferralGiftData } from "../../../../helpers/referral-gifts/extractReferralGiftData";

interface ReferralPromoListProps {
  onItemSelect?: (item: IPromocode) => void;
}

function ReferralPromoList({ onItemSelect }: ReferralPromoListProps) {
  const dispatch = useDispatch();
  const { promoList, loadingStatus, selectedReferralGiftRow, pendingChanges } =
    useSelect((state) => ({
      promoList: state.getVouchersReducer.shownPromoList || [],
      loadingStatus: state.getVouchersReducer.loadingStatus,
      selectedReferralGiftRow: state.referralGiftsReducer.selectedRow,
      pendingChanges: state.referralGiftsReducer.pendingChanges,
    }));

  const currency = useGetCurrency();

  useEffect(() => {
    dispatch(getVouchers({ is_voucher: true }));
  }, [dispatch]);

  const handleSelectVoucher = (voucher: IPromocode) => {
    if (!selectedReferralGiftRow) return;
    const existingChanges = pendingChanges[selectedReferralGiftRow.index];
    const voucherDisplayName = `${voucher.max_discount_value} ${currency} OFF`;
    const giftType: "promotion" | "item" = "promotion";

    const senderData = selectedReferralGiftRow.columnType === "sender"
      ? {
        sender_referral_gift_id: voucher.id,
        sender_referral_gift_type: giftType,
        sender_referral_gift_price: voucher.max_discount_value,
        sender_referral_gift_name: voucherDisplayName,
        sender_referral_gift_image: CouponIcon,
      }
      : (existingChanges?.data ? extractReferralGiftData(existingChanges.data, "sender") : {});

    const recipientData = selectedReferralGiftRow.columnType === "recipient"
      ? {
        recipient_referral_gift_id: voucher.id,
        recipient_referral_gift_type: giftType,
        recipient_referral_gift_price: voucher.max_discount_value,
        recipient_referral_gift_name: voucherDisplayName,
        recipient_referral_gift_image: CouponIcon,
      }
      : (existingChanges?.data ? extractReferralGiftData(existingChanges.data, "recipient") : {});

    dispatch(
      modifyReferralGift({
        data: {
          ...senderData,
          ...recipientData,
          referral_id: selectedReferralGiftRow.referral_id,
          id: selectedReferralGiftRow.id,
        },
        columnType: selectedReferralGiftRow.columnType,
        rowIndex: selectedReferralGiftRow.index,
      })
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.giftsWrapper}>
        {loadingStatus === LoadingStatus.loading && (
          <div className={styles.noItems}>
            <LoadingPageInfo />
          </div>
        )}

        {loadingStatus === LoadingStatus.success && promoList.length > 0 && (
          <div className={styles.categorySection}>
            <div className={styles.items}>
              {promoList.map((voucher) => (
                <div key={voucher.id} className={styles.itemContainer}>
                  <div className={styles.itemDetails}>
                    <div className={styles.itemImage}>
                      <img
                        src={CouponIcon}
                        alt={`${voucher.max_discount_value} ${currency} OFF`}
                        className={styles.voucherImage}
                      />
                    </div>
                    <div className={styles.itemInfo}>
                      <span className={styles.itemName}>
                        {voucher.max_discount_value} {currency} OFF
                      </span>
                    </div>
                  </div>
                  <button
                    onClick={() => handleSelectVoucher(voucher)}
                    className={styles.addItemBtn}
                    aria-label={`Add ${voucher.max_discount_value} ${currency} OFF`}
                  >
                    <Plus color="#FF9000" size={26} />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        {loadingStatus === LoadingStatus.success && promoList.length === 0 && (
          <div className={styles.noItems}>
            <span>No Promocodes found</span>
          </div>
        )}

        {loadingStatus === LoadingStatus.failed && (
          <div className={styles.noItems}>
            <span>Failed to load promocodes</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(ReferralPromoList);
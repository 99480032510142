import { BranchesInterval, StoreWorkingHours } from "../types";

export const transformBranchIntervals = (branches: BranchesInterval[]) =>
    branches.reduce((acc, branch) => {
        acc[branch.branch_id] = {
            pickup_intervals: branch.pickup_intervals,
            delivery_intervals: branch.delivery_intervals,
        };
        return acc;
    }, {} as { [branch_id: string]: StoreWorkingHours });

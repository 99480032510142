import { action, payload } from "ts-action";
import * as constants from "../../../constants";
import { IReferralMenuCategory } from "../../../types/referral-gifts-types";

export const getReferralMenu = action(
    constants.getReferralMenuAction.requested,
    payload<{ store_id?: string }>()
);

export const getReferralMenuSuccess = action(
    constants.getReferralMenuAction.fulfilled,
    payload<{ menu: IReferralMenuCategory[] }>()
);

export const getReferralMenuFailure = action(
    constants.getReferralMenuAction.rejected,
    payload<Error>()
);

export const searchReferralMenuItems = action(
    constants.ACTION_SEARCH_REFERRAL_MENU_ITEMS,
    payload<{ search_term: string }>()
);
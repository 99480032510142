import { ReferralProgramGift } from "../../types/referral-gifts-types";

export const createEmptyReferralGift = (
  index: number
): ReferralProgramGift => ({
  index,
  id: "",
  referral_id: "",
  sender_referral_gift_id: "",
  sender_referral_gift_price: "",
  sender_referral_gift_name: "",
  sender_referral_gift_type: "item",
  recipient_referral_gift_id: "",
  recipient_referral_gift_price: "",
  recipient_referral_gift_name: "",
  recipient_referral_gift_type: "item",
  is_complete: false,
  recently_added: false,
});

import React, { useCallback, useEffect, useMemo } from "react";
import styles from "./styles.module.sass";
import ReferralGiftCard from "../ReferralGiftCard";
import { ReferralProgramGift } from "../../../../types/referral-gifts-types";
import { useDispatch } from "react-redux";
import {
  deleteReferralGift,
  modifyReferralGift,
  removeEmptyReferralGift,
  resetReferralGiftStatus,
  selectReferralGift,
} from "../../../../redux-store/actions";
import { useSelect } from "../../../../hooks/useSelect";
import { Trash2 } from "react-feather";

interface ReferralGiftRowProps {
  item: ReferralProgramGift;
  index: number;
}

const ReferralGiftRow: React.FC<ReferralGiftRowProps> = ({ item, index }) => {
  const dispatch = useDispatch();
  const {
    currentIndex,
    currentColumnType,
    selectedSenderReferralGiftId,
    selectedRecipientReferralGiftId,
  } = useSelect((state) => ({
    currentIndex: state.referralGiftsReducer.selectedRow?.index,
    selectedRecipientReferralGiftId:
      state.referralGiftsReducer.selectedRow?.recipient_referral_gift_id,
    selectedSenderReferralGiftId:
      state.referralGiftsReducer.selectedRow?.sender_referral_gift_id,
    currentColumnType: state.referralGiftsReducer.selectedRow?.columnType,
  }));
  const senderAdded = Boolean(item.sender_referral_gift_id);
  const recipientAdded = Boolean(item.recipient_referral_gift_id);
  const isCurrentRowSelected = currentIndex === index;
  const isSenderSelected =
    currentColumnType === "sender" && isCurrentRowSelected;
  const isRecipientSelected =
    currentColumnType === "recipient" && isCurrentRowSelected;
  const isComplete = Boolean(item.is_complete);
  const isRecentlyAdded = Boolean(item.recently_added);

  const handleSenderSelect = useCallback(() => {
    dispatch(
      selectReferralGift({
        index: item.index,
        columnType: "sender",
        referral_id: item.referral_id ?? undefined,
        recipient_referral_gift_id: item.recipient_referral_gift_id,
        id: item.id ?? undefined,
      })
    );
  }, [dispatch, item.index, item.referral_id, item.recipient_referral_gift_id, item.id]);

  const handleRecipientSelect = useCallback(() => {
    dispatch(
      selectReferralGift({
        index: item.index,
        columnType: "recipient",
        referral_id: item.referral_id ?? undefined,
        sender_referral_gift_id: item.sender_referral_gift_id,
        id: item.id ?? undefined,
      })
    );
  }, [dispatch, item.id, item.index, item.referral_id, item.sender_referral_gift_id]);

  const handleDeleteRow = useCallback(() => {
    const deleteAction = item.referral_id
      ? deleteReferralGift({ referral_id: item.referral_id })
      : removeEmptyReferralGift({ index: item.index, gift: item });

    dispatch(deleteAction);
  }, [dispatch, item]);

  const connectionLineClassName = useMemo(() => {
    const baseClass = styles.horizontalLine;
    const isCurrentRowSelected = currentIndex === index;
    if (isCurrentRowSelected) {
      if (item.is_complete) {
        return `${baseClass} ${styles.completeReferralGift}`;
      }
      if (isSenderSelected) {
        if (recipientAdded) {
          return `${baseClass} ${styles.bothActive}`;
        }
        return `${baseClass} ${styles.senderActiveOnly}`;
      }
      if (isRecipientSelected) {
        if (senderAdded) {
          return `${baseClass} ${styles.bothActive}`;
        }
        return `${baseClass} ${styles.recipientActiveOnly}`;
      }
    }
    return `${baseClass}`;
  }, [currentIndex, index, item.is_complete, isSenderSelected, isRecipientSelected, recipientAdded, senderAdded]);

  useEffect(() => {
    if (isRecentlyAdded && typeof currentIndex === "number") {
      dispatch(resetReferralGiftStatus(currentIndex));
    }
  }, [currentIndex, dispatch, isRecentlyAdded]);
  return (
    <div
      className={`${styles.row} ${item.recently_added ? styles.highlighted : ""
        }`}
    >
      <div className={styles.item}>
        <ReferralGiftCard
          isEmpty={!recipientAdded}
          item={{
            id: item.recipient_referral_gift_id,
            name: item.recipient_referral_gift_name,
            image: item.recipient_referral_gift_image,
            price: item.recipient_referral_gift_price as number,
            type: item.recipient_referral_gift_type,
            recently_added: item.recently_added,
            is_complete: item.is_complete,
          }}
          onSelect={handleRecipientSelect}
          isActive={isRecipientSelected}
          isSelectedRow={currentIndex === index}
        />
      </div>
      <div className={styles.connectionContainer}>
        <div className={item.recently_added ? styles.connectionSuccess : ""}>
          <div className={connectionLineClassName}></div>
        </div>

        <button className={styles.deleteRowButton} onClick={handleDeleteRow}>
          <Trash2 color="#8E8E93" size={16} />
        </button>
      </div>
      <div className={styles.item}>
        <ReferralGiftCard
          isEmpty={!senderAdded}
          item={{
            id: item.sender_referral_gift_id,
            name: item.sender_referral_gift_name,
            image: item.sender_referral_gift_image,
            price: item.sender_referral_gift_price as number,
            type: item.sender_referral_gift_type,
            recently_added: item.recently_added,
            is_complete: item.is_complete,
          }}
          onSelect={handleSenderSelect}
          isActive={isSenderSelected}
          isSelectedRow={currentIndex === index}
        />
      </div>
    </div>
  );
};

export default React.memo(ReferralGiftRow);

import axios from "./axios";
import { AxiosReturn } from "./types";
import * as Requests from "./requests";
import { IStore } from "../types";

interface IEditStoreData {
  translated_name?: string;
  ar_name?: string;
  en_name?: string;
  bio?: string;
  store_image?: string;
  cover_image?: string;
  background_color?: string;
  facebook_link?: string;
  instagram_link?: string;
  snapchat_link?: string;
  twitter_link?: string;
  welcoming_gift_status: "enabled" | "disabled";
  referral_program_status: "enabled" | "disabled";
}

export const editStore = (token, data: IEditStoreData): AxiosReturn<IStore> => {
  return axios.put(Requests.store, data, {
    headers: {
      token,
    },
  });
};

export default {
  open: "فتح",
  close: "غلق",
  delivery: "خدمة التوصيل",
  pickup: "إستلام من الفرع",
  save: "حفظ",
  branchesOpeningHours: "Branches opening hours",
  openingHoursCustomizedToEveryBranch:
    "Opening hours customized to every branch",
  updateBranches: "Update Branches",
  noBranchesFound: "No Branches Found",
  searchForBranchName: "Search for branch name…",
  pleaseWaitWhileUpdatingAllBranches:
    "Please wait while updating all branches...",
  customizeOpeningHoursPerBranch: "+ Customize opening hours per branch",
  active: "نشط",
  delete: "حذف",
  activate: "تفعيل",
  confirm: "تأكيد",
  addWorkingHoursSchedule: "أضف جدول ساعات العمل",
  workingHoursScheduleName: "اسم جدول ساعات العمل",
  workingHoursScheduleDescription: "أدخل اسمًا لجدول ساعات العمل",
  deleteWorkingHoursSchedule: "حذف جدول ساعات العمل",
  deleteWorkingHoursScheduleConfirmation:
    "هل أنت متأكد أنك تريد حذف جدول ساعات العمل هذا؟",
  yesDeleteIt: "نعم، احذفه",
  activateWorkingHoursSchedule: "تفعيل جدول ساعات العمل",
  activateWorkingHoursScheduleConfirmation:
    "هل أنت متأكد أنك تريد تفعيل جدول ساعات العمل هذا؟",
  workingHoursScheduleActivatedSuccessfully: "تم تفعيل جدول ساعات العمل بنجاح!",
  somethingWentWrong: "حدث خطأ ما، يرجى المحاولة مرة أخرى.",
  workingHoursScheduleCreatedSuccessfully: "تم إنشاء جدول ساعات العمل بنجاح!",
  workingHoursScheduleDeletedSuccessfully: "تم حذف جدول ساعات العمل بنجاح!",
};

import { all } from "redux-saga/effects";
import { watchGetReferralGiftsSaga } from "./getReferralGiftsSaga";
import { watchDeleteReferralGiftSaga } from "./deleteReferralGiftSaga";
import { watchModifyReferralGiftSaga } from "./modifyReferralGiftSaga";

export default function* referralGiftsSagas() {
  yield all([
    watchGetReferralGiftsSaga(),
    watchDeleteReferralGiftSaga(),
    watchModifyReferralGiftSaga(),
  ]);
}

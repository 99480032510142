import { genericAction, genericFormFlowAction } from "../../../constants";

const ACTION_CREATE_ORDERING_MENU = "ACTION_CREATE_ORDERING_MENU";
export const createOrderingMenuAction = genericAction(
  ACTION_CREATE_ORDERING_MENU
);
const ACTION_GET_EXTRA_INFO = "ACTION_GET_EXTRA_INFO";
export const getExtraInfoAction = genericAction(ACTION_GET_EXTRA_INFO);
const ACTION_GET_STATISTICS = "ACTION_GET_STATISTICS";
export const getStatisticsAction = genericAction(ACTION_GET_STATISTICS);
const ACTION_GET_STORE_INFO = "ACTION_GET_STORE_INFO";
export const getStoreInfoAction = genericAction(ACTION_GET_STORE_INFO);
const ACTION_GET_BRANCH_INFO = "ACTION_GET_BRANCH_INFO";
export const getBranchInfoAction = genericAction(ACTION_GET_BRANCH_INFO);
const ACTION_GET_ORDERING_ITEMS = "ACTION_GET_ORDERING_ITEMS";
export const getOrderingItemsAction = genericAction(ACTION_GET_ORDERING_ITEMS);
const ACTION_EDIT_ORDERING_ITEM = "ACTION_EDIT_ORDERING_ITEM";
export const editOrderingItemAction = genericAction(ACTION_EDIT_ORDERING_ITEM);
const ACTION_REORDER_ITEM = "ACTION_REORDER_ITEM";
export const reOrderItemAction = genericAction(ACTION_REORDER_ITEM);

const ACTION_CREATE_ORDERING_CATEGORY = "ACTION_CREATE_ORDERING_CATEGORY";
export const createOrderingCategoryAction = genericAction(
  ACTION_CREATE_ORDERING_CATEGORY
);
const ACTION_ORDER = "ACTION_ORDER";
export const orderAction = genericAction(ACTION_ORDER);
const ACTION_GET_ORDER_DETAILS = "ACTION_GET_ORDER_DETAILS";
export const getOrderDetailsAction = genericAction(ACTION_GET_ORDER_DETAILS);
const ACTION_GET_CUSTOMER_INFO = "ACTION_GET_CUSTOMER_INFO";
export const getCustomerInfoAction = genericAction(ACTION_GET_CUSTOMER_INFO);

const ACTION_ORDER_TRACKING = "ACTION_ORDER_TRACKING";
export const orderTrackingAction = genericAction(ACTION_ORDER_TRACKING);
const ACTION_GET_PAST_ORDER = "ACTION_GET_PAST_ORDER";
export const getPastOrderAction = genericAction(ACTION_GET_PAST_ORDER);
const ACTION_GET_ORDERING_MENUS = "ACTION_GET_ORDERING_MENUS";

export const getOrderingMenusAction = genericAction(ACTION_GET_ORDERING_MENUS);

const ACTION_GET_ORDERING_CATEGORIES = "ACTION_GET_ORDERING_CATEGORIES";

export const getOrderingCategoriesAction = genericAction(
  ACTION_GET_ORDERING_CATEGORIES
);

const ACTION_GET_ORDERING_MODIFIER_GROUPS =
  "ACTION_GET_ORDERING_MODIFIER_GROUPS";
export const getOrderingModifierGroupsAction = genericAction(
  ACTION_GET_ORDERING_MODIFIER_GROUPS
);

const ACTION_CREATE_ORDERING_ITEM = "ACTION_CREATE_ORDERING_ITEM";
export const createOrderingItemAction = genericAction(
  ACTION_CREATE_ORDERING_ITEM
);

const ACTION_CREATE_ORDERING_MODIFIERS_GROUPS =
  "ACTION_CREATE_ORDERING_MODIFIERS_GROUPS";
export const createOrderingModifiersGroupsAction = genericAction(
  ACTION_CREATE_ORDERING_MODIFIERS_GROUPS
);

const ACTION_GET_SUGGESTED_MODIFIERS_GROUPS =
  "ACTION_GET_SUGGESTED_MODIFIERS_GROUPS";
export const getSuggestedModifiersGroupsAction = genericAction(
  ACTION_GET_SUGGESTED_MODIFIERS_GROUPS
);

const ACTION_REORDERING_CATEGORY_ITEM = "ACTION_REORDERING_CATEGORY_ITEM";
export const reOrderCategoryAction = genericAction(
  ACTION_REORDERING_CATEGORY_ITEM
);

const ACTION_EDIT_ORDERING_CATEGORIES = "ACTION_EDIT_ORDERING_CATEGORIES";
export const editOrderingCategories = genericAction(
  ACTION_EDIT_ORDERING_CATEGORIES
);

const ACTION_DELETE_ORDERING_CATEGORY = "ACTION_DELETE_ORDERING_CATEGORY";
export const deleteOrderingMenuCategoryAction = genericAction(
  ACTION_DELETE_ORDERING_CATEGORY
);

const ACTION_DELETE_ORDERING_ITEM = "ACTION_DELETE_ORDERING_ITEM";
export const deleteOrderingMenuItemAction = genericAction(
  ACTION_DELETE_ORDERING_ITEM
);

const ACTION_POST_SUGGESTED_MODIFIERS_GROUPS =
  "ACTION_POST_SUGGESTED_MODIFIERS_GROUPS";
export const createOrderingSuggestedModifiersGroupsAction = genericAction(
  ACTION_POST_SUGGESTED_MODIFIERS_GROUPS
);

const ACTION_DELETE_ORDERING_MODIFIER_GROUP =
  "ACTION_DELETE_ORDERING_MODIFIER_GROUP";
export const deleteOrderingSuggestedModifiersGroupAction = genericAction(
  ACTION_DELETE_ORDERING_MODIFIER_GROUP
);
const ACTION_EDIT_ORDERING_MODIFIER_GROUP =
  "ACTION_EDIT_ORDERING_MODIFIER_GROUP";
export const editOrderingSuggestedModifiersGroupAction = genericAction(
  ACTION_EDIT_ORDERING_MODIFIER_GROUP
);

const ACTION_DELETE_ORDERING_MODIFIER = "ACTION_DELETE_ORDERING_MODIFIER";
export const deleteOrderingModifier = genericAction(
  ACTION_DELETE_ORDERING_MODIFIER
);

const ACTION_CHANGE_ORDERING_ITEM_CATEGORY =
  "ACTION_CHANGE_ORDERING_ITEM_CATEGORY";
export const changeOrderingItemCategoryAction = genericAction(
  ACTION_CHANGE_ORDERING_ITEM_CATEGORY
);

const ACTION_SUBMIT_PICKUP_BRANCHES = "ACTION_SUBMIT_PICKUP_BRANCHES";
export const submitPickupBranchesAction = genericAction(
  ACTION_SUBMIT_PICKUP_BRANCHES
);

const ACTION_GET_STORE_SETTINGS = "ACTION_GET_STORE_SETTINGS";
export const getStoreSettingsAction = genericAction(ACTION_GET_STORE_SETTINGS);

const ACTION_EDIT_STORE_WORKING_HOURS = "ACTION_EDIT_STORE_WORKING_HOURS";
export const editStoreWorkingHoursAction = genericAction(
  ACTION_EDIT_STORE_WORKING_HOURS
);

// RESET WORKING HOURS SCHEDULE
export const RESET_WORKING_HOURS_SCHEDULE = "RESET_WORKING_HOURS_SCHEDULE";
// SELECT WORKING HOURS SCHEDULE
export const SELECT_WORKING_HOURS_SCHEDULE = "SELECT_WORKING_HOURS_SCHEDULE";
// DELETE WORKING HOURS SCHEDULE
export const DELETE_WORKING_HOURS_SCHEDULE = "DELETE_WORKING_HOURS_SCHEDULE";
export const deleteWorkingHoursScheduleAction = genericAction(
  DELETE_WORKING_HOURS_SCHEDULE
);

// GET WORKING HOURS SCHEDULES
export const GET_WORKING_HOURS_SCHEDULES = "GET_WORKING_HOURS_SCHEDULES";
export const getWorkingHoursSchedulesAction = genericAction(
  GET_WORKING_HOURS_SCHEDULES
);

// ACTIVATE WORKING HOURS SCHEDULE
export const ACTIVATE_WORKING_HOURS_SCHEDULE =
  "ACTIVATE_WORKING_HOURS_SCHEDULE";
export const activateWorkingHoursScheduleAction = genericAction(
  ACTIVATE_WORKING_HOURS_SCHEDULE
);

// CREATE WORKING HOURS SCHEDULE
export const CREATE_WORKING_HOURS_SCHEDULE = "CREATE_WORKING_HOURS_SCHEDULE";
export const createWorkingHoursScheduleAction = genericAction(
  CREATE_WORKING_HOURS_SCHEDULE
);

const ACTION_EDIT_STORE_SETTINGS = "ACTION_EDIT_STORE_SETTINGS";
export const editStoreSettingsAction = genericAction(
  ACTION_EDIT_STORE_SETTINGS
);

const ACTION_GET_BRAND_CATEGORY = "ACTION_GET_BRAND_CATEGORY";
export const getBrandCategoryAction = genericAction(ACTION_GET_BRAND_CATEGORY);

const ACTION_GET_BRAND_TAGS = "ACTION_GET_BRAND_TAGS";
export const getBrandTagsAction = genericAction(ACTION_GET_BRAND_TAGS);

const ACTION_GET_ALL_BRAND_CATEGORIES = "ACTION_GET_ALL_BRAND_CATEGORIES";
export const getAllBrandCategoriesAction = genericAction(
  ACTION_GET_ALL_BRAND_CATEGORIES
);

const ACTION_GET_ALL_BRAND_TAGS = "ACTION_GET_ALL_BRAND_TAGS";
export const getAllBrandTagsAction = genericAction(ACTION_GET_ALL_BRAND_TAGS);

const ACTION_GET_ZONES = "ACTION_GET_ZONES";
export const getZonesAction = genericAction(ACTION_GET_ZONES);
export const ACTION_SEARCH_FOR_ZONES = "ACTION_SEARCH_FOR_ZONES";
export const ACTION_SEARCH_FOR_DISTRICTS = "ACTION_SEARCH_FOR_DISTRICTS";
const ACTION_GET_AREAS = "ACTION_GET_AREAS";
export const getDistrictsAction = genericAction(ACTION_GET_AREAS);
const ACTION_GET_ORDERING_PORTAL_LINK = "ACTION_GET_ORDERING_PORTAL_LINK";
export const getOrderingPortalLinkAction = genericAction(
  ACTION_GET_ORDERING_PORTAL_LINK
);
export const ACTION_REMOVE_DELIVERY_ZONE = "ACTION_REMOVE_DELIVERY_ZONE";

const ACTION_EDIT_BRANCH_ZONES = "ACTION_EDIT_BRANCH_ZONES";
export const editBranchZonesAction = genericFormFlowAction(
  ACTION_EDIT_BRANCH_ZONES
);
const ACTION_GET_WEB_PORTAL_HISTORY = "ACTION_GET_WEB_PORTAL_HISTORY";
export const getWebPortalHistoryAction = genericAction(
  ACTION_GET_WEB_PORTAL_HISTORY
);
const ACTION_GET_WEB_PORTAL_STATISTICS = "ACTION_GET_WEB_PORTAL_STATISTICS";
export const getWebPortalStatisticsAction = genericAction(
  ACTION_GET_WEB_PORTAL_STATISTICS
);
const ACTION_GET_MIN_ORDER_VALUE = "ACTION_GET_MIN_ORDER_VALUE";
export const getMinOrderValueAction = genericAction(ACTION_GET_MIN_ORDER_VALUE);

const ACTION_SET_MIN_ORDER_VALUE = "ACTION_SET_MIN_ORDER_VALUE";
export const setMinOrderValueAction = genericAction(ACTION_SET_MIN_ORDER_VALUE);

const ACTION_SET_ROUTING_METHOD = "ACTION_SET_ROUTING_METHOD";
export const setRoutingMethodAction = genericAction(ACTION_SET_ROUTING_METHOD);

const ACTION_SET_PAYMENT_METHOD = "ACTION_SET_PAYMENT_METHOD";
export const setPaymentMethodAction = genericAction(ACTION_SET_PAYMENT_METHOD);

const ACTION_IMPORT_MENU = "ACTION_IMPORT_MENU";
export const importMenuAction = genericAction(ACTION_IMPORT_MENU);

const ACTION_GET_MENU_SCRAPING_STATE = "ACTION_GET_MENU_SCRAPING_STATE";
export const getMenuScrapingStateAction = genericAction(
  ACTION_GET_MENU_SCRAPING_STATE
);

// verify menu csv action
const ACTION_VERIFY_MENU_CSV = "ACTION_VERIFY_MENU_CSV";
export const verifyMenuCSVAction = genericAction(ACTION_VERIFY_MENU_CSV);

// upload menu csv action
const ACTION_UPLOAD_MENU_CSV = "ACTION_UPLOAD_MENU_CSV";
export const uploadMenuCSVAction = genericAction(ACTION_UPLOAD_MENU_CSV);

// upload menu csv action
export const ACTION_UPLOAD_CUSTOMER_POINTS_CSV =
  "ACTION_UPLOAD_CUSTOMER_POINTS_CSV";
export const uploadCustomerPointsCSVAction = genericAction(
  ACTION_UPLOAD_CUSTOMER_POINTS_CSV
);

// download csv
const ACTION_DOWNLOAD_EXCEL_SHEET = "ACTION_DOWNLOAD_EXCEL_SHEET";
export const downloadExcelSheetAction = genericAction(
  ACTION_DOWNLOAD_EXCEL_SHEET
);
// verify zone csv action
const ACTION_VERIFY_ZONE_CSV = "ACTION_VERIFY_ZONE_CSV";
export const verifyZoneCSVAction = genericAction(ACTION_VERIFY_ZONE_CSV);

// upload zone csv action
const ACTION_UPLOAD_ZONE_CSV = "ACTION_UPLOAD_ZONE_CSV";
export const uploadZoneCSVAction = genericAction(ACTION_UPLOAD_ZONE_CSV);

// get store working hours
const ACTION_GET_STORE_WORKING_HOURS = "ACTION_GET_STORE_WORKING_HOURS";
export const getStoreWorkingHoursAction = genericAction(
  ACTION_GET_STORE_WORKING_HOURS
);

// update store working hours
const ACTION_UPDATE_STORE_WORKING_HOURS = "ACTION_UPDATE_STORE_WORKING_HOURS";
export const updateStoreWorkingHoursAction = genericAction(
  ACTION_UPDATE_STORE_WORKING_HOURS
);
// update branch working hours field
export const updateBranchWorkingHoursFieldAction = "ACTION_UPDATE_BRANCH_WORKING_HOURS_FIELD";

// get branch working hours
const ACTION_GET_BRANCH_WORKING_HOURS = "ACTION_GET_BRANCH_WORKING_HOURS";
export const getBranchWorkingHoursAction = genericAction(
  ACTION_GET_BRANCH_WORKING_HOURS
);

// set branches form changed
export const setBranchesFormChangedAction = "SET_BRANCHES_FORM_CHANGED_ACTION";
// set store form changed
export const setStoreFormChangedAction = "SET_STORE_FORM_CHANGED_ACTION";

// clear branches working hours
const ACTION_CLEAR_WORKING_HOURS_SCHEDULE =
  "ACTION_CLEAR_WORKING_HOURS_SCHEDULE";
export const clearWorkingHoursScheduleAction = ACTION_CLEAR_WORKING_HOURS_SCHEDULE;

// clear previous state
export const ACTION_CLEAR_PREVIOUS_STATE = "ACTION_CLEAR_PREVIOUS_STATE";
// update branches working hours
const ACTION_UPDATE_BRANCHES_WORKING_HOURS =
  "ACTION_UPDATE_BRANCHES_WORKING_HOURS";
export const updateBranchesWorkingHoursAction = genericAction(
  ACTION_UPDATE_BRANCHES_WORKING_HOURS
);

// get ordering qr portal status
const ACTION_GET_ORDERING_QR_PORTAL_STATUS =
  "ACTION_GET_ORDERING_QR_PORTAL_STATUS";
export const getOrderingQRPortalStatusAction = genericAction(
  ACTION_GET_ORDERING_QR_PORTAL_STATUS
);

// delete ordering menu
const ACTION_DELETE_ORDERING_MENU = "ACTION_DELETE_ORDERING_MENU";
export const deleteOrderingMenuAction = genericAction(
  ACTION_DELETE_ORDERING_MENU
);

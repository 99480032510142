import { ReferralProgramGift } from "../../../../types/referral-gifts-types";
import axios from "../axios";
import { REFERRAL_PROGRAM_API } from "../requests";

export const getReferralGiftsApi = (token: string) => {
    return axios.get<ReferralProgramGift[]>(REFERRAL_PROGRAM_API, {
        headers: {
            token,
        },
    });
};
import { on, reducer } from "ts-action";
import { withLoadingReducer } from "../../../Components/Pickup/redux-store/reducers/withLoadingState";
import { mapById } from "../../../hooks/use-cashiers";
import { getReferralMenuAction } from "../../../constants";
import * as actions from "../../actions";
import {
    IReferralMenuCategory,
    IReferralMenuItem,
} from "../../../types/referral-gifts-types";

export interface ReferralGiftInfo {
    id: string;
    columnType: "sender" | "recipient";
    index: number;
    referral_id?: string;
}
export interface ReferralMenuState {
    categoriesIds: string[];
    shownCategoriesIds: string[];
    shownCategoriesById: Record<string, IReferralMenuCategory>;
    categoriesById: Record<string, IReferralMenuCategory>;
    existingGiftsMap: Map<string, ReferralGiftInfo>;
    search: string;
    loadingStatus?: string;
}

const initialState: ReferralMenuState = {
    categoriesIds: [],
    shownCategoriesIds: [],
    shownCategoriesById: {},
    categoriesById: {},
    existingGiftsMap: new Map(),
    search: "",
};

const removePreviousGiftsAtIndex = (
    giftsMap: Map<string, ReferralGiftInfo>,
    index: number,
    columnType: "sender" | "recipient"
): Map<string, ReferralGiftInfo> => {
    const newGiftsMap = new Map(giftsMap);

    for (const [key, info] of giftsMap.entries()) {
        if (info.index === index && info.columnType === columnType) {
            newGiftsMap.delete(key);
        }
    }

    return newGiftsMap;
};
const filterCategories = (
    categories: Record<string, IReferralMenuCategory>,
    searchTerm: string
): Record<string, IReferralMenuCategory> => {
    if (!searchTerm.trim()) return categories;

    const lowercaseSearch = searchTerm.toLowerCase();

    const matchesSearch = (item: IReferralMenuItem) => {
        return [item.name, item.en_name, item.ar_name].some((text) =>
            text?.toLowerCase().includes(lowercaseSearch)
        );
    };

    return Object.entries(categories).reduce(
        (filtered, [categoryId, category]) => {
            const filteredItems = category.items.filter(matchesSearch);

            if (filteredItems.length > 0) {
                filtered[categoryId] = { ...category, items: filteredItems };
            }

            return filtered;
        },
        {} as Record<string, IReferralMenuCategory>
    );
};

const filterCategoriesByGiftIds = (
    categories: Record<string, IReferralMenuCategory>,
    existingGiftsMap: Map<string, ReferralGiftInfo>
): Record<string, IReferralMenuCategory> => {
    const existingIds = new Set(existingGiftsMap.keys());

    return Object.entries(categories).reduce(
        (filtered, [categoryId, category]) => {
            const filteredItems = category.items.filter(
                (item) => !existingIds.has(item.id)
            );

            if (filteredItems.length > 0) {
                filtered[categoryId] = { ...category, items: filteredItems };
            }

            return filtered;
        },
        {} as Record<string, IReferralMenuCategory>
    );
};

const referralMenuReducer = withLoadingReducer<ReferralMenuState>(
    reducer<ReferralMenuState>(
        [
            on(actions.getReferralMenuFailure, (state) => ({
                ...state,
                categoriesIds: [],
                shownCategoriesIds: [],
                categoriesById: {},
                shownCategoriesById: {},
            })),

            on(actions.getReferralMenuSuccess, (state, { payload }) => {
                const categories = payload.menu || [];
                const categoriesById = mapById(categories);
                const categoriesIds = categories.map((category) => category.id);
                const filteredCategoriesById = filterCategoriesByGiftIds(
                    categoriesById,
                    state.existingGiftsMap
                );
                console.log(state.existingGiftsMap);
                return {
                    ...state,
                    categoriesIds,
                    categoriesById,
                    shownCategoriesIds: Object.keys(filteredCategoriesById),
                    shownCategoriesById: filteredCategoriesById,
                };
            }),

            on(actions.searchReferralMenuItems, (state, { payload }) => {
                const searchTerm = payload.search_term;

                if (!searchTerm) {
                    return {
                        ...state,
                        search: "",
                        shownCategoriesById: state.categoriesById,
                        shownCategoriesIds: state.categoriesIds,
                    };
                }

                let filteredCategories = filterCategories(
                    state.categoriesById,
                    searchTerm
                );
                filteredCategories = filterCategoriesByGiftIds(
                    filteredCategories,
                    state.existingGiftsMap
                );

                return {
                    ...state,
                    search: searchTerm,
                    shownCategoriesById: filteredCategories,
                    shownCategoriesIds: Object.keys(filteredCategories),
                };
            }),
            on(actions.getReferralGiftsSuccess, (state, { payload }) => {
                const existingGiftsMap = new Map<string, ReferralGiftInfo>();

                payload.forEach((gift, index) => {
                    if (gift.sender_referral_gift_id) {
                        existingGiftsMap.set(gift.sender_referral_gift_id, {
                            id: gift.sender_referral_gift_id,
                            columnType: "sender",
                            index,
                            referral_id: gift.referral_id,
                        });
                    }

                    if (gift.recipient_referral_gift_id) {
                        existingGiftsMap.set(gift.recipient_referral_gift_id, {
                            id: gift.recipient_referral_gift_id,
                            columnType: "recipient",
                            index,
                            referral_id: gift.referral_id,
                        });
                    }
                });
                return {
                    ...state,
                    existingGiftsMap,
                };
            }),
            on(actions.modifyReferralGift, (state, { payload }) => {
                const { data, rowIndex } = payload;
                let newExistingGiftsMap = new Map(state.existingGiftsMap);

                if (data?.recipient_referral_gift_id) {
                    newExistingGiftsMap = removePreviousGiftsAtIndex(
                        newExistingGiftsMap,
                        rowIndex,
                        "recipient"
                    );
                    newExistingGiftsMap.set(data.recipient_referral_gift_id, {
                        id: data.recipient_referral_gift_id,
                        columnType: "recipient",
                        index: rowIndex,
                        referral_id: data.referral_id,
                    });
                }
                if (data?.sender_referral_gift_id) {
                    newExistingGiftsMap = removePreviousGiftsAtIndex(
                        newExistingGiftsMap,
                        rowIndex,
                        "sender"
                    );
                    newExistingGiftsMap.set(data.sender_referral_gift_id, {
                        id: data.sender_referral_gift_id,
                        columnType: "sender",
                        index: rowIndex,
                        referral_id: data.referral_id,
                    });
                }
                let filteredCategoriesById = filterCategoriesByGiftIds(
                    state.categoriesById,
                    newExistingGiftsMap
                );

                return {
                    ...state,
                    existingGiftsMap: newExistingGiftsMap,
                    shownCategoriesIds: Object.keys(filteredCategoriesById),
                    shownCategoriesById: filteredCategoriesById,
                };
            }),
            on(actions.deleteReferralGiftSuccess, (state, { payload }) => {
                const newExistingGiftsMap = new Map(state.existingGiftsMap);
                newExistingGiftsMap.delete(payload.sender_referral_gift_id);
                newExistingGiftsMap.delete(payload.recipient_referral_gift_id);
                let filteredCategoriesById = filterCategoriesByGiftIds(
                    state.categoriesById,
                    newExistingGiftsMap
                );
                return {
                    ...state,
                    existingGiftsMap: newExistingGiftsMap,
                    shownCategoriesById: filteredCategoriesById,
                    shownCategoriesIds: Object.keys(filteredCategoriesById),
                };
            }),
            on(actions.removeEmptyReferralGift, (state, { payload }) => {
                const newExistingGiftsMap = new Map(state.existingGiftsMap);
                const gift = payload.gift;

                if (gift.sender_referral_gift_id) {
                    newExistingGiftsMap.delete(gift.sender_referral_gift_id);
                }
                if (gift.recipient_referral_gift_id) {
                    newExistingGiftsMap.delete(gift.recipient_referral_gift_id);
                }

                const filteredCategoriesById = filterCategoriesByGiftIds(
                    state.categoriesById,
                    newExistingGiftsMap
                );

                return {
                    ...state,
                    existingGiftsMap: newExistingGiftsMap,
                    shownCategoriesById: filteredCategoriesById,
                    shownCategoriesIds: Object.keys(filteredCategoriesById),
                };
            }),
        ],
        initialState
    ),
    getReferralMenuAction
);

export default referralMenuReducer;

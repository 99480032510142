import { sortBy, times } from "lodash";
import { ReferralProgramGift } from "../../types/referral-gifts-types";
import { createEmptyReferralGift } from "./createEmptyReferralGift";

export const prepareReferralGiftCollection = (
  gifts: ReferralProgramGift[]
): ReferralProgramGift[] => {
  const completeGifts = gifts.filter((gift) => gift.is_complete);
  const incompleteGifts = gifts.filter(
    (gift) =>
      !gift.is_complete &&
      (gift.sender_referral_gift_id || gift.recipient_referral_gift_id)
  );
  const emptyGifts = gifts.filter(
    (gift) =>
      !gift.is_complete &&
      !gift.sender_referral_gift_id &&
      !gift.recipient_referral_gift_id
  );

  const sortedCompleteGifts = sortBy(completeGifts, (gift) => {
    const price = Number(gift.recipient_referral_gift_price) || 0;
    return isNaN(price) ? 0 : price;
  });

  const combinedGifts = [
    ...sortedCompleteGifts,
    ...incompleteGifts,
    ...emptyGifts,
  ].map((gift, idx) => ({
    ...gift,
    index: idx,
  }));

  const requiredEmptyRecords = Math.max(0, 5 - combinedGifts.length);
  const result = [
    ...combinedGifts,
    ...times(requiredEmptyRecords, (idx) =>
      createEmptyReferralGift(idx + combinedGifts.length)
    ),
  ];

  return result;
};

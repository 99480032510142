import { watchAddLoyaltySpecialItemSaga } from "./addLoyaltySpecialItemSaga";
import { watchDeleteLoyaltySpecialItemSaga } from "./deleteLoyaltySpecialItemSaga";
import { watchEditLoyaltyMenuItemSaga } from "./editLoyaltyMenuItemSaga";
import { watchEditLoyaltySpecialItemSaga } from "./editLoyaltySpecialItemSaga";
import { watchGetLoyaltyMenuSaga } from "./getLoyaltyMenuSaga";
import { watchGetLoyaltySpecialItemsSaga } from "./getLoyaltySpecialItemsSaga";
import { watchGetReferralMenuSaga } from "./getReferralMenuSaga";

export default [
  watchGetLoyaltyMenuSaga(),
  watchEditLoyaltyMenuItemSaga(),
  watchGetLoyaltySpecialItemsSaga(),
  watchDeleteLoyaltySpecialItemSaga(),
  watchEditLoyaltySpecialItemSaga(),
  watchAddLoyaltySpecialItemSaga(),
  watchGetReferralMenuSaga(),
];

import React from "react";
import styles from "./styles.module.sass";
import { LoadingStatus } from "../../../../Components/Pickup/redux-store/reducers/withLoadingState";
import { LoadingPageInfo } from "../../../../Components/Leaderboard/LoadingPageInfo";
import ReferralGiftRow from "./ReferralGiftRow";
import { useSelect } from "../../../../hooks/useSelect";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { addEmptyReferralGift } from "../../../../redux-store/actions";

const ReferralGiftList: React.FC = () => {
  const { referralGifts, loadingStatus } = useSelect((state) => ({
    referralGifts: state.referralGiftsReducer.referralGifts,
    loadingStatus: state.referralGiftsReducer.loadingStatus,
  }));
  const dispatch = useDispatch();
  if (loadingStatus === LoadingStatus.loading) {
    return <LoadingPageInfo />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerRow}>
        <div className={styles.header}>
          <h3 className={styles.title}>Sender Gives</h3>
        </div>
        <div className={styles.header}>
          <h3 className={styles.title}>Sender Gets</h3>
        </div>
      </div>

      <div className={styles.rows}>
        {referralGifts.map((item, index) => (
          <ReferralGiftRow key={index} item={item} index={index} />
        ))}
      </div>

      <div className={styles.addRowButtonContainer}>
        <button
          className={styles.addRowButton}
          onClick={() => {
            dispatch(addEmptyReferralGift());
          }}
        >
          <PlusOutlined className={styles.addRowButtonIcon} />
          <span>Add Gifts</span>
        </button>
      </div>
    </div>
  );
};

export default ReferralGiftList;

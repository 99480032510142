import { getBranchWorkingHoursAction } from "../../../../constants/actions";
import { StoreWorkingHours } from "../../../../../..//types/Manager";
import { on, reducer } from "ts-action";
import { withLoadingReducer } from "../../withLoadingState";
import {
  getBranchWorkingHoursSuccess,
  updateBranchWorkingHoursField,
  updateBranchesWorkingHoursSuccess,
  clearWorkingHoursSchedule,
  setBranchesFormChanged,
} from "../../../actions";
import { transformBranchIntervals } from "../../../../../../helpers/transformBranchIntervals";
import { updateBranchIntervals } from "../../../../../../helpers/updateBranchIntervals";

export type BranchesWorkingHoursState = {
  branchesWorkingHours: {
    [branch_id: string]: StoreWorkingHours;
  };
  previousBranchesWorkingHours: {
    [branch_id: string]: StoreWorkingHours;
  };
  branchesFormChanged: boolean;
};
const initialValue: BranchesWorkingHoursState = {
  branchesWorkingHours: {},
  previousBranchesWorkingHours: {},
  branchesFormChanged: false,
};

export const branchesWorkingHoursReducer =
  withLoadingReducer<BranchesWorkingHoursState>(
    reducer<BranchesWorkingHoursState>(
      [
        on(getBranchWorkingHoursSuccess, (state, { payload }) => {
          return {
            ...state,
            branchesWorkingHours: { ...state.branchesWorkingHours, ...payload },
            previousBranchesWorkingHours: {
              ...state.previousBranchesWorkingHours,
              ...payload,
            },
          };
        }),
        on(updateBranchesWorkingHoursSuccess, (state, { payload }) => ({
          ...state,
          branchesWorkingHours: {
            ...state.branchesWorkingHours,
            ...transformBranchIntervals(payload.branches_intervals),
          },
          previousBranchesWorkingHours: {
            ...state.previousBranchesWorkingHours,
            ...transformBranchIntervals(payload.branches_intervals),
          },
        })),
        on(updateBranchWorkingHoursField, (state, { payload }) => ({
          ...updateBranchIntervals(state, payload),
        })),
        on(setBranchesFormChanged, (state, { payload }) => ({
          ...state,
          branchesFormChanged: payload,
        })),
        on(clearWorkingHoursSchedule, () => initialValue),
      ],
      initialValue
    ),
    getBranchWorkingHoursAction
  );

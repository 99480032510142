import React, { useState, useEffect, useRef, useMemo, memo } from "react";
import styles from "./styles.module.sass";
import { useTranslation } from "../../../../shopx-shared-components/hooks/useTranslation";
import Strings from "../../../../i18n/strings/addFromMenu";
import { useSelect } from "../../../../hooks/useSelect";
import ReferralCategoryItemsList from "../ReferralCategoryItemsList";
import { useDispatch } from "react-redux";
import { getReferralMenu, searchReferralMenuItems } from "../../../../redux-store/actions/menu-actions/referral-menu-actions";
import { LoadingPageInfo } from "../../../../Components/Leaderboard/LoadingPageInfo";
import { LoadingStatus } from "../../../../Components/Pickup/redux-store/reducers/withLoadingState";
import SearchIcon from "../../../../assets/search.svg";

function ReferralOrderingMenu() {
    const { t } = useTranslation("addFromMenu");
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const apiCalledRef = useRef(false);
    const categoryRefs = useRef<Record<string, HTMLDivElement>>({});
    const tabsRef = useRef<HTMLDivElement>(null);
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    const isScrollingProgrammatically = useRef(false);
    const isDragging = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);

    const {
        referralMenuReducer: {
            shownCategoriesById,
            shownCategoriesIds,
            loadingStatus: getReferralMenuLoadingStatus,
        },
        store
    } = useSelect((state) => ({
        referralMenuReducer: state.referralMenuReducer,
        store: state.storeReducer.store
    }));

    useEffect(() => {
        if (!apiCalledRef.current && store?.id) {
            dispatch(getReferralMenu({
                store_id: store.id
            }));
            apiCalledRef.current = true;
        }
    }, [dispatch, store?.id]);

    useEffect(() => {
        if (shownCategoriesIds.length > 0 && !selectedCategory) {
            setSelectedCategory(shownCategoriesIds[0]);
        }
    }, [shownCategoriesIds, selectedCategory]);

    useEffect(() => {
        const container = scrollContainerRef.current;
        if (!container) return;

        const handleScroll = () => {
            if (isScrollingProgrammatically.current) return;
            if (getReferralMenuLoadingStatus !== LoadingStatus.success ||
                shownCategoriesIds.length === 0) return;

            const scrollPosition = container.scrollTop + 150;

            let closestCategory = shownCategoriesIds[0];
            let minDistance = Infinity;

            for (const categoryId of shownCategoriesIds) {
                const element = categoryRefs.current[categoryId];
                if (!element) continue;

                const distance = Math.abs(element.offsetTop - scrollPosition);
                if (distance < minDistance) {
                    minDistance = distance;
                    closestCategory = categoryId;
                }
            }

            if (closestCategory !== selectedCategory) {
                setSelectedCategory(closestCategory);
                scrollTabIntoView(closestCategory);
            }
        };

        container.addEventListener('scroll', handleScroll);
        return () => container.removeEventListener('scroll', handleScroll);
    }, [shownCategoriesIds, selectedCategory, getReferralMenuLoadingStatus]);

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        dispatch(searchReferralMenuItems({ search_term: value }));
    };

    const scrollTabIntoView = (categoryId) => {
        const tabsContainer = tabsRef.current;
        if (!tabsContainer) return;

        const tabElement = document.getElementById(`tab-${categoryId}`);
        if (!tabElement) return;

        const containerRect = tabsContainer.getBoundingClientRect();
        const tabRect = tabElement.getBoundingClientRect();

        if (tabRect.left < containerRect.left || tabRect.right > containerRect.right) {
            const scrollLeft = tabElement.offsetLeft - tabsContainer.clientWidth / 2 + tabElement.clientWidth / 2;
            tabsContainer.scrollTo({
                left: scrollLeft,
                behavior: 'smooth'
            });
        }
    };

    const handleCategorySelect = (categoryId) => {
        setSelectedCategory(categoryId);

        if (categoryRefs.current[categoryId] && scrollContainerRef.current) {
            const container = scrollContainerRef.current;
            const element = categoryRefs.current[categoryId];
            const elementPosition = element.offsetTop;
            const headerHeight = 150;

            isScrollingProgrammatically.current = true;

            container.scrollTo({
                top: elementPosition - headerHeight,
                behavior: 'smooth'
            });

            setTimeout(() => {
                isScrollingProgrammatically.current = false;
            }, 1000);


            scrollTabIntoView(categoryId);
        }
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        const container = tabsRef.current;
        if (!container) return;
        isDragging.current = true;
        startX.current = e.pageX - container.offsetLeft;
        scrollLeft.current = container.scrollLeft;
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isDragging.current || !tabsRef.current) return;
        e.preventDefault();
        const x = e.pageX - tabsRef.current.offsetLeft;
        const walk = (x - startX.current) * 1.5;
        tabsRef.current.scrollLeft = scrollLeft.current - walk;
    };

    const stopDragging = () => {
        isDragging.current = false;
    };


    const categoryTabs = useMemo(() => {
        if (getReferralMenuLoadingStatus !== LoadingStatus.success || shownCategoriesIds.length === 0) {
            return null;
        }

        return (
            <div className={styles.tabsContainer}>
                <div className={styles.tabs}
                    ref={tabsRef}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={stopDragging}
                    onMouseUp={stopDragging}>
                    {shownCategoriesIds.map(categoryId => (
                        <button
                            id={`tab-${categoryId}`}
                            key={categoryId}
                            className={`${styles.tab} ${selectedCategory === categoryId ? styles.selectedTab : ''}`}
                            onClick={() => handleCategorySelect(categoryId)}
                        >
                            {shownCategoriesById[categoryId].en_name || shownCategoriesById[categoryId].ar_name}
                        </button>
                    ))}
                </div>
            </div>
        );
    }, [shownCategoriesIds, shownCategoriesById, selectedCategory, getReferralMenuLoadingStatus]);

    const categoryListContent = useMemo(() => {
        if (getReferralMenuLoadingStatus === LoadingStatus.loading) {
            return (
                <div className={styles.noItems}>
                    <LoadingPageInfo />
                </div>
            );
        }

        if (getReferralMenuLoadingStatus === LoadingStatus.success && shownCategoriesIds.length > 0) {
            return shownCategoriesIds.map((categoryId) => (
                <div
                    key={categoryId}
                    ref={el => el && (categoryRefs.current[categoryId] = el)}
                    className={styles.categorySection}
                    id={`category-${categoryId}`}
                >
                    <ReferralCategoryItemsList
                        categoryName={shownCategoriesById[categoryId].en_name || shownCategoriesById[categoryId].ar_name}
                        items={shownCategoriesById[categoryId].items.map(item => ({
                            ...item,
                            category_id: categoryId
                        }))}
                    />
                </div>
            ));
        }

        if ([LoadingStatus.success, LoadingStatus.failed].includes(getReferralMenuLoadingStatus as any) &&
            shownCategoriesIds.length === 0) {
            return (
                <div className={styles.noItems}>
                    <span>{t(Strings.emptyItems)}</span>
                </div>
            );
        }

        return null;
    }, [shownCategoriesIds, shownCategoriesById, getReferralMenuLoadingStatus, t]);

    return (
        <div className={styles.wrapper}>
            {/* Fixed header section */}
            <div className={styles.headerSection}>
                <div className={styles.box}>
                    <div className={styles.search}>
                        <img
                            alt={t(Strings.itemSearchPlaceholder)}
                            src={SearchIcon}
                            className={styles.logo}
                        />
                        <input
                            placeholder={t(Strings.itemSearchPlaceholder)}
                            className={styles.input}
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>

                {categoryTabs}
            </div>

            {/* Scrollable content area */}
            <div className={styles.giftsWrapper} ref={scrollContainerRef}>
                {categoryListContent}
            </div>
        </div>
    );
}

export default memo(ReferralOrderingMenu);
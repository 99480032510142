import { action, payload } from "ts-action";
import { getVouchersAction } from "../../../constants";
import { IPromocode } from "../../../types";

export const getVouchers = action(
    getVouchersAction.requested,
    payload<{ is_voucher?: boolean }>()
);

export const getVouchersSuccess = action(
    getVouchersAction.fulfilled,
    payload<{ data: IPromocode[], is_voucher?: boolean }>()
);

export const getVouchersFailure = action(
    getVouchersAction.rejected,
    payload<Error>()
);
export default {
  open: "Open",
  close: "Close",
  delivery: "Delivery",
  pickup: "Pickup",
  save: "Save",
  branchesOpeningHours: "Branches opening hours",
  openingHoursCustomizedToEveryBranch:
    "Opening hours customized to every branch",
  updateBranches: "Update Branches",
  noBranchesFound: "No Branches Found",
  pleaseWaitWhileUpdatingAllBranches:
    "Please wait while updating all branches...",
  customizeOpeningHoursPerBranch: "+ Customize opening hours per branch",
  searchForBranchName: "Search for branch name…",
  active: "Active",
  delete: "Delete",
  activate: "Activate",
  confirm: "Confirm",
  yesDeleteIt: "Yes, delete it",
  addWorkingHoursSchedule: "Add Working Hours Schedule",
  workingHoursScheduleName: "Working Hours Schedule Name",
  workingHoursScheduleDescription:
    "Enter a Schedule name for the working hours",
  deleteWorkingHoursSchedule: "Delete Working Hours Schedule",
  deleteWorkingHoursScheduleConfirmation:
    "Are you sure you want to delete this working hours Schedule?",
  activateWorkingHoursSchedule: "Activate Working Hours Schedule",
  activateWorkingHoursScheduleConfirmation:
    "Are you sure you want to activate this working hours Schedule?",
  workingHoursScheduleActivatedSuccessfully:
    "Working hours schedule activated successfully!",
  somethingWentWrong: "Something went wrong, please try again.",
  workingHoursScheduleCreatedSuccessfully:
    "Working hours schedule created successfully!",
  workingHoursScheduleDeletedSuccessfully:
    "Working hours schedule deleted successfully!",
};

import {
  RecipientReferralGift,
  SenderReferralGift,
} from "../../../../types/referral-gifts-types";
import axios from "../axios";
import { REFERRAL_PROGRAM_API } from "../requests";

export const modifyReferralGiftApi = (
  token: string,
  data: RecipientReferralGift &
    SenderReferralGift & {
      referral_id?: string;
    }
) => {
  return axios.put(`${REFERRAL_PROGRAM_API}/referral`, data, {
    headers: {
      token,
    },
  });
};

import { call, put, select, takeLatest } from "redux-saga/effects";
import { modifyReferralGiftAction } from "../../../constants";
import { modifyReferralGiftApi } from "../../../Components/Pickup/axios/referral-gifts";
import {
  modifyReferralGiftFailure,
  modifyReferralGiftSuccess,
  modifyReferralGift,
} from "../../actions";
import { selectToken } from "../../selectors";
import { IRootReducerState } from "../../rootReducer";
import { PendingChanges } from "../../reducers/referralGiftsReducer";
import { ReferralProgramGift } from "../../../types/referral-gifts-types";

const pendingChangeToGift = (
  pendingChange: PendingChanges
): ReferralProgramGift => ({
  is_complete: false,
  recently_added: false,
  index: pendingChange.rowIndex,
  referral_id: pendingChange.data.referral_id ?? "",
  id: pendingChange.data.id ?? "",
  sender_referral_gift_id: pendingChange.data.sender_referral_gift_id ?? "",
  sender_referral_gift_price:
    pendingChange.data.sender_referral_gift_price ?? "",
  sender_referral_gift_name: pendingChange.data.sender_referral_gift_name ?? "",
  sender_referral_gift_image:
    pendingChange.data.sender_referral_gift_image ?? "",
  sender_referral_gift_type:
    pendingChange.data.sender_referral_gift_type ?? "item",
  recipient_referral_gift_id:
    pendingChange.data.recipient_referral_gift_id ?? "",
  recipient_referral_gift_price:
    pendingChange.data.recipient_referral_gift_price ?? "",
  recipient_referral_gift_name:
    pendingChange.data.recipient_referral_gift_name ?? "",
  recipient_referral_gift_image:
    pendingChange.data.recipient_referral_gift_image ?? "",
  recipient_referral_gift_type:
    pendingChange.data.recipient_referral_gift_type ?? "item",
});
function* modifyReferralGiftSaga({
  payload,
}: ReturnType<typeof modifyReferralGift>) {
  try {
    const token = yield select(selectToken);
    const targetRow = yield select(
      (state: IRootReducerState) =>
        state.referralGiftsReducer.referralGifts[payload.rowIndex]
    );
    const pendingChanges = yield select(
      (state: IRootReducerState) => state.referralGiftsReducer.pendingChanges
    );

    const pendingChange = pendingChanges[payload.rowIndex];
    const isRowComplete = targetRow && targetRow.is_complete;

    if (isRowComplete && pendingChange) {
      const requestData = {
        ...(pendingChange.data.sender || {
          sender_referral_gift_id: targetRow.sender_referral_gift_id,
          sender_referral_gift_image: targetRow.sender_referral_gift_image,
          sender_referral_gift_name: targetRow.sender_referral_gift_name,
          sender_referral_gift_price: targetRow.sender_referral_gift_price,
          sender_referral_gift_type:
            targetRow.sender_referral_gift_type.toLowerCase(),
        }),
        ...(pendingChange.data.recipient || {
          recipient_referral_gift_id: targetRow.recipient_referral_gift_id,
          recipient_referral_gift_image:
            targetRow.recipient_referral_gift_image,
          recipient_referral_gift_name: targetRow.recipient_referral_gift_name,
          recipient_referral_gift_price:
            targetRow.recipient_referral_gift_price,
          recipient_referral_gift_type:
            targetRow.recipient_referral_gift_type.toLowerCase(),
        }),
        referral_id: pendingChange.data.referral_id?.trim()
          ? pendingChange.data.referral_id
          : targetRow.referral_id,
      };
      const remainingPendingChanges = { ...pendingChanges };
      delete remainingPendingChanges[payload.rowIndex];
      const pendingGifts = Object.values(remainingPendingChanges).map(
        (change) => pendingChangeToGift(change as PendingChanges)
      );

      const response = yield call(modifyReferralGiftApi, token, requestData);
      yield put(modifyReferralGiftSuccess([...response.data, ...pendingGifts]));
    }
  } catch (error) {
    yield put(modifyReferralGiftFailure(payload));
  }
}

export function* watchModifyReferralGiftSaga() {
  yield takeLatest(modifyReferralGiftAction.requested, modifyReferralGiftSaga);
}

import axios from "../axios";
import { REFERRAL_PROGRAM_API } from "../requests";

interface DeleteReferralGiftPayload {
    referral_id: string;
}

export const deleteReferralGiftApi = (token: string, data: DeleteReferralGiftPayload) => {
    return axios.delete(`${REFERRAL_PROGRAM_API}/referral`, {
        headers: {
            token,
        },
        data
    });
};
import React from "react";
import { SubMenu } from "../index";
import { Link } from "react-router-dom";
import styles from "../../styles.module.sass";
import { BrandListingsIcon } from "../../icons";
import Strings from "../../../../i18n/strings/navigation";
import { useTranslation } from "../../../../shopx-shared-components/hooks/useTranslation";
interface IProps {
  activeNavigate: (values) => boolean;
  collapsed: boolean;
}
const CollapseBrandListings: React.FC<IProps> = ({
  activeNavigate,
  collapsed,
}) => {
  const { t } = useTranslation("navigation");
  return (
    <SubMenu
      active={activeNavigate("/brand")}
      key={"brand"}
      title={t(Strings.brandListing)}
      icon={<BrandListingsIcon clicked={activeNavigate("brand")} />}
      clicked={activeNavigate("brand")}
      collapsed={collapsed}
    >
      <Link
        to={"/brand/info"}
        className={
          activeNavigate("info")
            ? styles.activeCollapseItem
            : styles.collapseItem
        }
      >
        {t(Strings.brandInfo)}
      </Link>
      <Link
        to={"/brand/visuals"}
        className={
          activeNavigate("visuals")
            ? styles.activeCollapseItem
            : styles.collapseItem
        }
      >
        {t(Strings.brandVisuals)}
      </Link>
      <Link
        to={"/brand/menu"}
        className={
          activeNavigate("/brand/menu")
            ? styles.activeCollapseItem
            : styles.collapseItem
        }
      >
        {t(Strings.menuItems)}
      </Link>
      <Link
        to={"/brand/gift-list"}
        className={
          activeNavigate("gift-list")
            ? styles.activeCollapseItem
            : styles.collapseItem
        }
      >
        {t(Strings.giftList)}
      </Link>
      <Link
        to={"/brand/referral-gifts"}
        className={
          activeNavigate("referral-gifts")
            ? styles.activeCollapseItem
            : styles.collapseItem
        }
      >
        {t(Strings.referralGifts)}
      </Link>
      <Link
        to={"/brand/converting_ratio"}
        className={
          activeNavigate("converting_ratio")
            ? styles.activeCollapseItem
            : styles.collapseItem
        }
      >
        {t(Strings.convertingRatio)}
      </Link>
      <Link
        to={"/brand/social"}
        className={
          activeNavigate("social")
            ? styles.activeCollapseItem
            : styles.collapseItem
        }
      >
        {t(Strings.socialLinks)}
      </Link>
      <Link
        to={"/brand/gifting_points"}
        className={
          activeNavigate("gifting_points")
            ? styles.activeCollapseItem
            : styles.collapseItem
        }
      >
        {t(Strings.giftingPoints)}
      </Link>
    </SubMenu>
  );
};

export default CollapseBrandListings;

import { memo } from "react";
import styles from "./styles.module.sass";
import { Plus } from "react-feather";
import { useSelect } from "../../../../hooks/useSelect";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import FoodIcon from "../../../../Components/Pickup/assets/food.svg";
import { IReferralMenuItem } from "../../../../types/referral-gifts-types";
import { useDispatch } from "react-redux";
import { modifyReferralGift } from "../../../../redux-store/actions";
import { useGetCurrency } from "../../../../Utils/getLocalizedCurrency";
import { extractReferralGiftData } from "../../../../helpers/referral-gifts/extractReferralGiftData";

export const pickupURL = process.env
  .REACT_APP_IMAGE_ORDERING_ENDPOINT as string;

export const prefixPickupUrl = (item: IReferralMenuItem): string => {
  if (item.image === undefined || item.image === null) {
    return FoodIcon;
  }
  const hasDataURL = (item.image as string).startsWith("data:");
  const hasBlobURL = item.image.startsWith("blob:");

  return hasDataURL || hasBlobURL ? item.image : pickupURL + item.image;
};

type ReferralCategoryItemsListProps = {
  categoryName: string;
  items: IReferralMenuItem[];
};

function ReferralCategoryItemsList({
  categoryName,
  items,
}: ReferralCategoryItemsListProps) {
  const {
    selectedRow,
    pendingChanges,
  } = useSelect((state) => ({
    storeReducer: state.storeReducer,
    selectedRow: state.referralGiftsReducer.selectedRow,
    pendingChanges: state.referralGiftsReducer.pendingChanges,
  }));
  const dispatch = useDispatch();
  const currency = useGetCurrency();

  const handleAddItem = (item: IReferralMenuItem) => {
    if (!selectedRow) return;

    const existingChanges = pendingChanges[selectedRow.index];
    const giftType: "promotion" | "item" = "item";
    const itemName = item.name || item.en_name || item.ar_name || "";
    const senderData = selectedRow.columnType === "sender"
      ? {
        sender_referral_gift_id: item.id,
        sender_referral_gift_type: giftType,
        sender_referral_gift_price: item.price || 0,
        sender_referral_gift_name: itemName,
        sender_referral_gift_image: item.image || "",
      }
      : (existingChanges?.data ? extractReferralGiftData(existingChanges.data, "sender") : {});

    const recipientData = selectedRow.columnType === "recipient"
      ? {
        recipient_referral_gift_id: item.id,
        recipient_referral_gift_type: giftType,
        recipient_referral_gift_price: item.price || 0,
        recipient_referral_gift_name: itemName,
        recipient_referral_gift_image: item.image || "",
      }
      : (existingChanges?.data ? extractReferralGiftData(existingChanges.data, "recipient") : {});

    dispatch(
      modifyReferralGift({
        data: {
          ...senderData,
          ...recipientData,
          referral_id: selectedRow.referral_id,
          id: selectedRow.id,
        },
        columnType: selectedRow.columnType,
        rowIndex: selectedRow.index,
      })
    );
  };
  return (
    <div className={styles.wrapper}>
      <span className={styles.categoryName}>{categoryName}</span>
      <div className={styles.items}>
        {(items || []).map((item) => (
          <div key={item.id} className={styles.itemContainer}>
            <div className={styles.itemDetails}>
              <div className={styles.itemImage}>
                {item.image ? (
                  <img
                    src={prefixPickupUrl(item)}
                    alt={item.name || item.en_name || item.ar_name}
                  />
                ) : (
                  <img
                    className={styles.iconWithoutImage}
                    src={FoodIcon}
                    alt="Food item"
                  />
                )}
              </div>
              <div className={styles.itemInfo}>
                <span className={styles.itemName}>
                  {item.name || item.en_name || item.ar_name}
                </span>
                <span className={styles.itemPrice}>
                  {item.price} {currency}
                </span>
              </div>
            </div>
            <button
              onClick={() => handleAddItem(item)}
              className={styles.addItemBtn}
              disabled={item.loading}
            >
              {item.loading ? (
                <Spin
                  style={{ color: "#FF9000" }}
                  indicator={<LoadingOutlined style={{ fontSize: 25 }} />}
                />
              ) : (
                <Plus color="#FF9000" size={26} />
              )}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default memo(ReferralCategoryItemsList);
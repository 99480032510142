import { translate } from "../../helpers/translate";
import Strings from "../../i18n/strings/navigation";
const t = translate("navigation");
export type mainRoute = {
  [key: string]: {
    route: string;
    keyWord: string;
  };
};
export const orderingPortalRoute: mainRoute = {
  [t(Strings.orderingPortal)]: {
    route: "/pickup/setup/ordering-portal",
    keyWord: "ordering-portal",
  },
};

export const qrOrderingPortal: mainRoute = {
  [t(Strings.qrOrdering)]: {
    route: "/pickup/setup/ordering-qr-portal",
    keyWord: "ordering-qr-portal",
  },
};
export const orderHistoryRoute: mainRoute = {
  [t(Strings.orderHistory)]: {
    route: "/pickup/setup/order-history",
    keyWord: "order-history",
  },
};
export const orderingRoutes = (
  hasPickUpEnabled: boolean,
  hasOrderHistory: boolean,
  hasPortalEnabled: boolean,
  hasQRPortalEnabled: boolean
): mainRoute => {
  if (hasPickUpEnabled)
    return {
      [t(Strings.pickupBranches)]: {
        route: "/pickup/setup/pickup-branches/select-branches",
        keyWord: "select-branches",
      },
      [t(Strings.menu)]: { route: "/pickup/setup/menu", keyWord: "menu" },
      [t(Strings.brandSettings)]: {
        route: "/pickup/setup/settings",
        keyWord: "settings",
      },
      ...(hasOrderHistory ? orderHistoryRoute : {}),
      ...(hasPortalEnabled ? orderingPortalRoute : {}),
      ...(hasQRPortalEnabled ? qrOrderingPortal : {}),
    };
  else return {};
};

export const insightsRoutes: mainRoute = {
  [t(Strings.transactions)]: {
    keyWord: "transactions",
    route: "/insights/transactions",
  },
  [t(Strings.redemptions)]: {
    keyWord: "redemptions",
    route: "/insights/redemptions",
  },
  [t(Strings.customers)]: {
    keyWord: "customers",
    route: "/insights/customers",
  },
  [t(Strings.branches)]: {
    keyWord: "branches",
    route: "/insights/branches",
  },
  [t(Strings.fraudCenter)]: {
    keyWord: "fraud-center",
    route: "/insights/fraud-center",
  },
};

export const brandListingRoutes: mainRoute = {
  [t(Strings.brandInfo)]: {
    keyWord: "info",
    route: "/brand/info",
  },
  [t(Strings.brandVisuals)]: {
    keyWord: "visuals",
    route: "/brand/visuals",
  },
  [t(Strings.menuItems)]: {
    keyWord: "menu",
    route: "/brand/menu",
  },
  [t(Strings.giftList)]: {
    keyWord: "gift-list",
    route: "/brand/gift-list",
  },
  [t(Strings.referralGifts)]: {
    keyWord: "referral-gifts",
    route: "/brand/referral-gifts",
  },
  [t(Strings.convertingRatio)]: {
    keyWord: "converting_ratio",
    route: "/brand/converting_ratio",
  },
  [t(Strings.socialLinks)]: {
    keyWord: "social",
    route: "/brand/social",
  },
  [t(Strings.giftingPoints)]: {
    keyWord: "gifting_points",
    route: "/brand/gifting_points",
  },
};

export const branchesAndCashierRoute: mainRoute = {
  [t(Strings.branchesAndCashiers)]: {
    keyWord: "branches_cashiers",
    route: "/settings/branches_cashiers",
  },
};
export const settingsRoutes = (
  hasAccessToBranches: boolean,
  hasAccess: boolean,
  hasPosEntities: boolean
): mainRoute => {
  return {
    ...(hasAccessToBranches ? branchesAndCashierRoute : {}),
    ...(hasAccessToBranches && hasAccess
      ? {
        ...(hasPosEntities
          ? {
            [t(Strings.posSettings)]: {
              keyWord: "pos",
              route: "/settings/pos",
            },
          }
          : {}),
        [t(Strings.dashboardUsers)]: {
          keyWord: "users",
          route: "/settings/users",
        },
        [t(Strings.advancedSettings)]: {
          keyWord: "advanced",
          route: "/settings/advanced",
        },
      }
      : {}),
  };
};

import axios from "../../axios";
import * as Requests from "../../requests";

export const getWorkingHoursSchedulesApi = (token: string) => {
    return axios.get(Requests.WORKING_HOURS_SCHEDULES_API, {
        headers: {
            token,
        },
        baseURL: process.env.PICKUP_ENDPOINTS,
    });
};

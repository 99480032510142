import * as React from "react";
import styles from "./styles.module.sass";
import ic_plus from "../../../../assets/ic_plus.svg";
import { useGetCurrency } from "../../../../Utils";
import photoUploadPlaceholder from "../../../../assets/photo-upload-placeholder.svg";
import CouponIcon from "../../../../assets/coupon.png";

interface ReferralGiftCardProps {
  isEmpty?: boolean;
  isActive?: boolean;
  item?: Partial<{
    id: string;
    name: string;
    image: string;
    price: number;
    type: string;
    recently_added?: boolean;
    is_complete?: boolean;
  }>;
  onSelect?: () => void;
  isSelectedRow?: boolean;
}

const ReferralGiftCard: React.FC<ReferralGiftCardProps> = ({
  isEmpty = true,
  isActive = false,
  item = {},
  onSelect,
  isSelectedRow,
}) => {
  const currency = useGetCurrency();

  const isPromotion = React.useMemo(
    () => item?.type?.toLowerCase() === "promotion",
    [item?.type]
  );

  const getImageSource = React.useMemo(() => {
    const itemType = item?.type?.toLowerCase();
    if (item?.image && itemType === "item") {
      return process.env.REACT_APP_IMAGE_ORDERING_ENDPOINT + item.image;
    }
    return itemType === "promotion" ? CouponIcon : photoUploadPlaceholder;
  }, [item?.image, item?.type]);

  const displayName = React.useMemo(() => {
    if (isEmpty) return "Add item";
    if (isPromotion) {
      return `${item.price ?? 0} ${currency} OFF`;
    }
    return item.name ?? "";
  }, [isEmpty, isPromotion, item.price, item.name, currency]);

  const wrapperClassName = React.useMemo(() => {
    return [
      styles.cardWrapper,
      item?.recently_added && styles.cardSuccess,
      isSelectedRow && !isEmpty && isActive && styles.activeCard,
      isActive && styles.activeCard,
    ]
      .filter(Boolean)
      .join(" ");
  }, [isActive, item?.recently_added, isSelectedRow, isEmpty]);

  const handleClick = React.useCallback(() => {
    if (onSelect) onSelect();
  }, [onSelect]);

  return (
    <div className={wrapperClassName} onClick={handleClick}>
      <div className={styles.imgWrapper}>
        {isEmpty ? (
          <img src={ic_plus} className={styles.img} alt="Add item" />
        ) : (
          <img
            src={getImageSource}
            style={{
              opacity: item?.image || isPromotion ? 1 : 0.5,
            }}
            className={item.image ? styles.itemImage : styles.iconWithoutImage}
            alt={item?.name || "Item image"}
            key={`img-${item?.id || "placeholder"}-${item?.image}`}
          />
        )}
      </div>
      <div className={styles.dataWrapper}>
        <p className={styles.itemName}>{displayName}</p>
        {!isEmpty && item?.price && !isPromotion && (
          <p className={styles.itemPrice}>
            {item.price} {currency}
          </p>
        )}
      </div>
    </div>
  );
};

export default React.memo(ReferralGiftCard);
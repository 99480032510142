import { action, payload } from "ts-action";
import {
  getReferralGiftsAction,
  addReferralGiftAction,
  updateReferralGiftAction,
  deleteReferralGiftsAction,
  MARK_GIFT_AS_UPDATED,
  MARK_GIFT_AS_COMPLETE,
  ADD_EMPTY_GIFT,
  modifyReferralGiftAction,
  ACTION_SELECT_REFERRAL_GIFT,
  ACTION_ADD_EMPTY_REFERRAL_GIFT,
  ACTION_REMOVE_EMPTY_REFERRAL_GIFT,
  ACTION_RESET_REFERRAL_GIFT_STATUS,
} from "../../constants";
import {
  AddReferralGiftPayload,
  DeleteReferralGiftPayload,
  ReferralGiftPayload,
  ReferralProgramGift,
  UpdateReferralGiftPayload,
} from "../../types/referral-gifts-types";

// Get Referral Gifts
export const getReferralGifts = action(getReferralGiftsAction.requested);

export const getReferralGiftsSuccess = action(
  getReferralGiftsAction.fulfilled,
  payload<ReferralProgramGift[]>()
);

export const getReferralGiftsFailure = action(
  getReferralGiftsAction.rejected,
  payload<Error>()
);

// Add Referral Gift
export const addReferralGift = action(
  addReferralGiftAction.requested,
  payload<{
    data: ReferralGiftPayload;
    index: number;
  }>()
);

export const addReferralGiftSuccess = action(
  addReferralGiftAction.fulfilled,
  payload<ReferralProgramGift[]>()
);

export const addReferralGiftFailure = action(
  addReferralGiftAction.rejected,
  payload<Error>()
);

// Update Referral Gift
export const updateReferralGift = action(
  updateReferralGiftAction.requested,
  payload<UpdateReferralGiftPayload>()
);

export const updateReferralGiftSuccess = action(
  updateReferralGiftAction.fulfilled,
  payload<ReferralProgramGift>()
);

export const updateReferralGiftFailure = action(
  updateReferralGiftAction.rejected,
  payload<Error>()
);

// Delete Referral Gift
export const deleteReferralGift = action(
  deleteReferralGiftsAction.requested,
  payload<DeleteReferralGiftPayload>()
);

export const deleteReferralGiftSuccess = action(
  deleteReferralGiftsAction.fulfilled,
  payload<{
    referral_id: string;
    sender_referral_gift_id: string;
    recipient_referral_gift_id: string;
  }>()
);

export const deleteReferralGiftFailure = action(
  deleteReferralGiftsAction.rejected,
  payload<Error>()
);

export const markGiftAsUpdated = action(
  MARK_GIFT_AS_UPDATED,
  payload<{ referral_id: string }>()
);

export const markGiftAsComplete = action(
  MARK_GIFT_AS_COMPLETE,
  payload<{ referral_id: string; is_complete: boolean }>()
);

export const addEmptyGift = action(ADD_EMPTY_GIFT);

export const modifyReferralGift = action(
  modifyReferralGiftAction.requested,
  payload<{
    data: ReferralGiftPayload;
    rowIndex: number;
    columnType: "sender" | "recipient";
  }>()
);
export const modifyReferralGiftSuccess = action(
  modifyReferralGiftAction.fulfilled,
  payload<ReferralProgramGift[]>()
);
export const modifyReferralGiftFailure = action(
  modifyReferralGiftAction.rejected,
  payload<{
    data: ReferralGiftPayload;
    rowIndex: number;
    columnType: "sender" | "recipient";
  }>()
);

export const selectReferralGift = action(
  ACTION_SELECT_REFERRAL_GIFT,
  payload<{
    index: number;
    columnType: "sender" | "recipient";
    sender_referral_gift_id?: string;
    recipient_referral_gift_id?: string;
    referral_id?: string;
    id?: string;
  }>()
);

export const addEmptyReferralGift = action(ACTION_ADD_EMPTY_REFERRAL_GIFT);

export const removeEmptyReferralGift = action(
  ACTION_REMOVE_EMPTY_REFERRAL_GIFT,
  payload<{
    index: number;
    gift: ReferralProgramGift;
  }>()
);

export const resetReferralGiftStatus = action(
  ACTION_RESET_REFERRAL_GIFT_STATUS,
  payload<number>()
);

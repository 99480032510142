import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../../../redux-store/selectors";
import {
    updateStoreWorkingHoursAction,
} from "../../../../constants/actions";
import {
    updateStoreWorkingHoursSuccess,
    updateStoreWorkingHoursFailure,
    updateStoreWorkingHours,
} from "../../../actions/Manager";
import {
    updateStoreWorkingHours as updateStoreWorkingHoursReq,
} from "../../../../axios/Manager";
import { notify } from "react-notify-toast";
import { union } from "ts-action";


const actionTypes = union({ updateStoreWorkingHours });
function* updateStoreWorkingHoursSaga(action: typeof actionTypes) {
    try {
        const token = yield select(selectToken);
        yield call(updateStoreWorkingHoursReq, token, action.payload);
        yield put(updateStoreWorkingHoursSuccess(action.payload.data));
        notify.show("Store working hours updated successfully", "success");
    } catch (error) {
        yield put(updateStoreWorkingHoursFailure(error as Error));
        notify.show("Failed to update store working hours!", "error");
    }
}

export function* watchUpdateStoreWorkingHoursSaga() {
    yield takeLatest(
        updateStoreWorkingHoursAction.requested,
        updateStoreWorkingHoursSaga
    );
}

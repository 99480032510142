import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../selectors";
import { getVouchersAPI } from "../../../axios/promotions/getVouchersAPI";
import { getVouchersFailure, getVouchersSuccess } from "../../actions";
import { getVouchersAction } from "../../../constants";

function* getVouchersSage({ payload }: { payload?: { is_voucher?: boolean } }) {
    try {
        const token = yield select(selectToken);
        const res = yield call(getVouchersAPI, token);
        yield put(getVouchersSuccess({
            data: res.data,
            is_voucher: payload?.is_voucher
        }));
    } catch (error) {
        yield put(getVouchersFailure(error as Error));
    }
}

export function* watchGetVouchersSaga() {
    yield takeLatest(getVouchersAction.requested, getVouchersSage);
}
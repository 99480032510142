import { call, put, select, takeLatest } from "redux-saga/effects";
import { getReferralGiftsAction } from "../../../constants";
import {
    getReferralGiftsSuccess,
    getReferralGiftsFailure
} from "../../actions/referralGiftsActions";
import { selectToken } from "../../selectors";
import { getReferralGiftsApi } from "../../../Components/Pickup/axios/referral-gifts";

function* getReferralGiftsSaga() {
    try {
        const token = yield select(selectToken);
        const response = yield call(getReferralGiftsApi, token);
        yield put(getReferralGiftsSuccess(response.data));
    } catch (error) {
        yield put(getReferralGiftsFailure(error as Error));
    }
}

export function* watchGetReferralGiftsSaga() {
    yield takeLatest(getReferralGiftsAction.requested, getReferralGiftsSaga);
}
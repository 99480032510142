import { on, reducer } from "ts-action";
import { withLoadingReducer } from "../../../Components/Pickup/redux-store/reducers/withLoadingState";
import { getVouchersAction } from "../../../constants";
import { IPromocode } from "../../../types";
import * as actions from "../../actions";
import { ReferralGiftInfo } from "../menu-reducers/referralMenuReducer";

export interface VouchersState {
  vouchers: IPromocode[];
  promoList: IPromocode[];
  shownPromoList: IPromocode[];
  existingGiftsMap: Map<string, ReferralGiftInfo>;
  loadingStatus?: string;
}

const initialState: VouchersState = {
  vouchers: [],
  promoList: [],
  shownPromoList: [],
  existingGiftsMap: new Map(),
};

const filterPromoListByExistingGifts = (
  promoList: IPromocode[],
  existingGiftsMap: Map<string, ReferralGiftInfo>
): IPromocode[] => {
  const existingIds = new Set(existingGiftsMap.keys());
  return promoList.filter((promo) => !existingIds.has(promo.id));
};

export default withLoadingReducer<VouchersState>(
  reducer<VouchersState>(
    [
      on(actions.getVouchers, (state) => ({
        ...state,
        promoList: [],
        shownPromoList: [],
      })),

      on(actions.getVouchersSuccess, (state, { payload }) => {
        if (payload.is_voucher) {
          const filteredPromoList = filterPromoListByExistingGifts(
            payload.data,
            state.existingGiftsMap
          );
          return {
            ...state,
            promoList: payload.data,
            shownPromoList: filteredPromoList,
          };
        }
        return {
          ...state,
          vouchers: payload.data,
        };
      }),

      on(actions.getVouchersFailure, () => initialState),

      on(actions.getReferralGiftsSuccess, (state, { payload }) => {
        const existingGiftsMap = new Map<string, ReferralGiftInfo>();
        payload.forEach((gift, index) => {
          if (gift.sender_referral_gift_id) {
            existingGiftsMap.set(gift.sender_referral_gift_id, {
              id: gift.sender_referral_gift_id,
              columnType: "sender",
              index,
              referral_id: gift.referral_id,
            });
          }
          if (gift.recipient_referral_gift_id) {
            existingGiftsMap.set(gift.recipient_referral_gift_id, {
              id: gift.recipient_referral_gift_id,
              columnType: "recipient",
              index,
              referral_id: gift.referral_id,
            });
          }
        });
        return {
          ...state,
          existingGiftsMap,
          shownPromoList: filterPromoListByExistingGifts(
            state.promoList,
            existingGiftsMap
          ),
        };
      }),

      on(actions.modifyReferralGift, (state, { payload }) => {
        const { data, rowIndex } = payload;
        let newExistingGiftsMap = new Map(state.existingGiftsMap);

        for (const [key, info] of Array.from(state.existingGiftsMap.entries())) {
          if (info.index === rowIndex && info.columnType === payload.columnType) {
            newExistingGiftsMap.delete(key);
          }
        }

        if (payload.columnType === "recipient" && data?.recipient_referral_gift_id) {
          newExistingGiftsMap.set(data.recipient_referral_gift_id, {
            id: data.recipient_referral_gift_id,
            columnType: "recipient",
            index: rowIndex,
            referral_id: data.referral_id,
          });
        }
        if (payload.columnType === "sender" && data?.sender_referral_gift_id) {
          newExistingGiftsMap.set(data.sender_referral_gift_id, {
            id: data.sender_referral_gift_id,
            columnType: "sender",
            index: rowIndex,
            referral_id: data.referral_id,
          });
        }
        return {
          ...state,
          existingGiftsMap: newExistingGiftsMap,
          shownPromoList: filterPromoListByExistingGifts(
            state.promoList,
            newExistingGiftsMap
          ),
        };
      }),

      on(actions.deleteReferralGiftSuccess, (state, { payload }) => {
        const newExistingGiftsMap = new Map(state.existingGiftsMap);
        newExistingGiftsMap.delete(payload.sender_referral_gift_id);
        newExistingGiftsMap.delete(payload.recipient_referral_gift_id);
        return {
          ...state,
          existingGiftsMap: newExistingGiftsMap,
          shownPromoList: filterPromoListByExistingGifts(
            state.promoList,
            newExistingGiftsMap
          ),
        };
      }),

      on(actions.removeEmptyReferralGift, (state, { payload }) => {
        const newExistingGiftsMap = new Map(state.existingGiftsMap);
        const gift = payload.gift;

        if (gift.sender_referral_gift_id) {
          newExistingGiftsMap.delete(gift.sender_referral_gift_id);
        }
        if (gift.recipient_referral_gift_id) {
          newExistingGiftsMap.delete(gift.recipient_referral_gift_id);
        }

        const updatedShownPromoList = filterPromoListByExistingGifts(
          state.promoList,
          newExistingGiftsMap
        );

        return {
          ...state,
          existingGiftsMap: newExistingGiftsMap,
          shownPromoList: updatedShownPromoList
        };
      }),
    ],
    initialState
  ),
  getVouchersAction
);

import * as React from "react";
import { useContext, useEffect } from "react";
import TabNavigation from "../../Components/TabNavigation";
import TabItem from "../../Components/TabNavigation/components/TabItem";
import styles from "./styles.module.sass";
import BreadCrumbs from "../../contexts/BreadCrumbs";
import NavigationStrings from "../../i18n/strings/navigation";
import { LoadingPageInfo } from "../../Components/Leaderboard/LoadingPageInfo";
import ReferralOrderingMenu from "./components/ReferralOrderingMenu";
import ReferralGiftList from "./components/ReferralGiftList";
import ReferralToggle from "./components/ReferralToggle";
import ReferralPromoList from "./components/ReferralPromoList";
import { useSelect } from "../../hooks/useSelect";
import { useDispatch } from "react-redux";
import { getReferralGifts } from "../../redux-store/actions";
import { LoadingStatus } from "../../Components/Pickup/redux-store/reducers/withLoadingState";

interface IProps {}

const ReferralGifts: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const { setCrumbs } = useContext(BreadCrumbs);
  const { isLoading, currentColumnType } = useSelect((state) => ({
    isLoading:
      state.referralGiftsReducer.loadingStatus === LoadingStatus.loading,
    currentColumnType: state.referralGiftsReducer.selectedRow?.columnType,
  }));

  useEffect(() => {
    setCrumbs([
      { name: NavigationStrings.brandListing, url: "", disabled: true },
      { name: NavigationStrings.referralGifts, url: "/brand/referral-gifts" },
    ]);
    dispatch(getReferralGifts());
  }, [setCrumbs, dispatch]);

  if (isLoading) {
    return (
      <div className={styles.loadingIndicator}>
        <LoadingPageInfo />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.toggleColumn}>
          <ReferralToggle />
        </div>

        <div className={styles.list}>
          <ReferralGiftList />
        </div>

        <div className={styles.form}>
          <TabNavigation disabled={false}>
            <TabItem title={<span className={styles.title}>Menu</span>}>
              <div>
                <ReferralOrderingMenu />
              </div>
            </TabItem>
            <TabItem
              title={<span className={styles.title}>Promocode List</span>}
            >
              <div>
                <ReferralPromoList />
              </div>
            </TabItem>
          </TabNavigation>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ReferralGifts);

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { SettingsSwitch } from "../../../../Components/BrandSettings/AdvancedSettings/SettingsSwitch";
import { editStore } from "../../../../redux-store/actions";
import { useSelect } from "../../../../hooks/useSelect";
import styles from "./styles.module.sass";

const ReferralToggle: React.FC = () => {
    const dispatch = useDispatch();
    const [referralProgramEnabled, setReferralProgramEnabled] = useState(false);
    const { store } = useSelect((state) => state.storeReducer);

    useEffect(() => {
        setReferralProgramEnabled(store.referral_program_status === "enabled")
    }, [store.referral_program_status]);

    const handleToggle = useCallback((checked: boolean) => {
        setReferralProgramEnabled(checked);
        dispatch(editStore([{ key: "referral_program_status", newValue: checked ? "enabled" : "disabled" }]));
    }, [dispatch]);

    return (
        <div className={styles.toggleColumn}>
            <div className={styles.toggleContainer}>
                <SettingsSwitch
                    onColor={"#FFF4EA"}
                    offColor={"#8E8E93"}
                    height={32}
                    onHandleColor={"#FFA351"}
                    offHandleColor={"#D1D1D6"}
                    handleDiameter={28}
                    width={56}
                    checked={referralProgramEnabled}
                    onChange={handleToggle}
                />
                <span className={styles.toggleLabel}>
                    Activate
                </span>
            </div>
        </div>
    );
};

export default React.memo(ReferralToggle);
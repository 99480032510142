export default {
  open: "open",
  close: "close",
  delivery: "delivery",
  pickup: "pickup",
  save: "save",
  branchesOpeningHours: "branchesOpeningHours",
  openingHoursCustomizedToEveryBranch: "openingHoursCustomizedToEveryBranch",
  updateBranches: "updateBranches",
  searchForBranchName: "searchForBranchName",
  noBranchesFound: "noBranchesFound",
  pleaseWaitWhileUpdatingAllBranches: "pleaseWaitWhileUpdatingAllBranches",
  customizeOpeningHoursPerBranch: "customizeOpeningHoursPerBranch",
  active: "active",
  delete: "delete",
  activate: "activate",
  confirm: "confirm",
  yesDeleteIt: "yesDeleteIt",
  addWorkingHoursSchedule: "addWorkingHoursSchedule",
  workingHoursScheduleName: "workingHoursScheduleName",
  workingHoursScheduleDescription: "workingHoursScheduleDescription",
  deleteWorkingHoursSchedule: "deleteWorkingHoursSchedule",
  deleteWorkingHoursScheduleConfirmation:
    "deleteWorkingHoursScheduleConfirmation",
  activateWorkingHoursSchedule: "activateWorkingHoursSchedule",
  activateWorkingHoursScheduleConfirmation:
    "activateWorkingHoursScheduleConfirmation",
  workingHoursScheduleActivatedSuccessfully:
    "workingHoursScheduleActivatedSuccessfully",
  somethingWentWrong: "somethingWentWrong",
  workingHoursScheduleCreatedSuccessfully:
    "workingHoursScheduleCreatedSuccessfully",
  workingHoursScheduleDeletedSuccessfully:
    "workingHoursScheduleDeletedSuccessfully",
};

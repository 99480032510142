import axios from "../../axios";
import * as Requests from "../../requests";
import { UpdateBranchesWorkingHoursPayload } from "../../../../../types";

// get branch working hours
export const getBranchWorkingHoursApi = (token: string, params: {
  branch_id: string, schedule_id: string
}) => {
  return axios.get(Requests.BRANCH_WORKING_HOURS_SCHEDULE_API(params), {
    headers: {
      token,
    },
    baseURL: process.env.PICKUP_ENDPOINTS,
  });
};

// update branches working hours
export const updateBranchesWorkingHoursApi = (
  token: string,
  payload: UpdateBranchesWorkingHoursPayload
) => {
  const { schedule_id, branches_intervals } = payload;
  return axios.put(Requests.updateBranchesWorkingHoursURL(schedule_id), { branches_intervals }, {
    headers: {
      token,
    },
    baseURL: process.env.PICKUP_ENDPOINTS,
  });
};

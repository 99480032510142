import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../../../redux-store/selectors";
import {
  getStoreWorkingHoursAction,
} from "../../../../constants/actions";
import {
  getStoreWorkingHoursSuccess,
  getStoreWorkingHoursFailure,
  getStoreWorkingHours,
} from "../../../actions/Manager";
import {
  getStoreWorkingHours as getStoreWorkingHoursReq,
} from "../../../../axios/Manager";
import { union } from "ts-action";

const actionTypes = union({ getStoreWorkingHours });
function* getStoreWorkingHoursSaga({ payload }: typeof actionTypes) {
  try {
    const token = yield select(selectToken);
    const res = yield call(getStoreWorkingHoursReq, token, payload);
    yield put(getStoreWorkingHoursSuccess(res.data));
  } catch (error) {
    yield put(getStoreWorkingHoursFailure(error as Error));
  }
}

export function* watchGetStoreWorkingHours() {
  yield takeLatest(
    getStoreWorkingHoursAction.requested,
    getStoreWorkingHoursSaga
  );
}